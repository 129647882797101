import { useSnackbar } from "notistack"
import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import LoadingContext from "./contexts/loadingContext"
import ServicosContext from "./contexts/servicosContext"
import Routes from "./routes"
import { Parametros } from "./services/integracao"

import RepresentanteSelector from "./functions/RepresentanteSelector"
import { RegistrarLogErro } from "./services/clientefiel"

function removeNull(obj) {
    if (Array.isArray(obj)) return obj

    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => [key, value === Object(value) ? removeNull(value) : value])
    )
}

export default function Permissoes() {
    const { setLoading } = useContext(LoadingContext)

    const autorizacao = localStorage.getItem("chaveSistema")
    const history = useHistory()

    let [configuracoes, setConfiguracoes] = React.useState(
        JSON.parse(localStorage.getItem("configs"))
    )

    const { enqueueSnackbar } = useSnackbar()

    const atualizarConfiguracoes = newConfig => {
        // Apagar configuração antiga de impressora
        delete newConfig.printer.impressora
        delete newConfig.printer.impressora2

        console.log("atualizarConfiguracoes", newConfig)

        const configString = JSON.stringify(removeNull(newConfig))
        localStorage.setItem("configs", configString)
        setConfiguracoes(JSON.parse(configString))
    }

    const acessarImagensTematicas = () => {
        try {
            if (configuracoes.user.parametros.urlSiteAplicativo) {
                window.open(
                    `https://${configuracoes.user.parametros.urlSiteAplicativo
                        .replace("https://", "")
                        .replace("http://", "")
                        .replace("/", "")}/downloads/imagensTematicas`,
                    "_blank"
                )
            } else {
                enqueueSnackbar(
                    "Seu usuário não está associado a nenhum gerador de imagens temáticas. Procure a equipe do suporte.",
                    { warning: "error" }
                )
            }
        } catch (error) {
            enqueueSnackbar(
                "Seu usuário não está associado a nenhum gerador de imagens temáticas. Procure a equipe do suporte.",
                { warning: "error" }
            )
        }
    }

    const buscarParametros = async (auth = autorizacao) => {
        try {
            const response = await Parametros(auth, setConfiguracoes, configuracoes)
            console.log("Parametros", response)
            if (response.retornoErro) {
                enqueueSnackbar(response.mensagem, { variant: "error" })
                return {}
            }

            setLoading(false)
            atualizarConfiguracoes(response)

            return response
        } catch (error) {
            RegistrarLogErro(error, "buscarParametros")
        }
        return {}
    }

    const handlePedidoDeMesa = async () => {
        setLoading(true)
        try {
            const configuracoes = await buscarParametros()
            if (configuracoes.user?.parametros?.id) {
                if (configuracoes.user?.parametros?.possuiModuloPedidosMesa) {
                    if (configuracoes.user?.parametros?.urlSiteAplicativo) {
                        history.push("/servicos/modulo/gerenciador-de-mesas")
                        if (!window.getCookie("infoGerenciarMesas")) {
                            enqueueSnackbar("Aqui você pode gerenciar suas mesas.")
                            window.setCookie("infoGerenciarMesas", "true", 9999)
                        }
                    } else {
                        enqueueSnackbar(
                            "Seu usuário não está associado a nenhum gerenciador de mesas. Procure a equipe do suporte.",
                            { variant: "error" }
                        )
                    }
                } else {
                    enqueueSnackbar(
                        "Esse usuário não possui Módulo de pedido de mesa contratado.",
                        { variant: "warning" }
                    )
                }
            } else {
                enqueueSnackbar("Erro ao identificar se você possui esse Módulo.", {
                    variant: "warning",
                })
            }
        } catch (error) {
            enqueueSnackbar(
                "Seu usuário não está associado a nenhum gerador de qrcodes de mesa. Procure a equipe do suporte.",
                { variant: "error" }
            )
        }
        setLoading(false)
    }

    const handleAceitadorDePedidos = async () => {
        setLoading(true)
        try {
            const configuracoes = await buscarParametros()
            if (configuracoes.user.parametros.id) {
                localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
                history.push("/servicos/modulo/recebedor-de-pedidos")
                if (!window.getCookie("infoRecebedor")) {
                    enqueueSnackbar("Aqui você recebe os seus pedidos.")
                    window.setCookie("infoRecebedor", "true", 9999)
                }
            } else {
                enqueueSnackbar("Infelizmente você está com o Delivery Inativo.", {
                    variant: "warning",
                })
            }
        } catch (error) {
            enqueueSnackbar("Houve um erro ao direcionar para o recebedor de pedidos.", {
                variant: "error",
            })

            RegistrarLogErro(error, "recebedorDePedidos")
        }
        setLoading(false)
    }

    const handleRegistradorDePedidos = async (acao = false) => {
        try {
            if (acao && configuracoes?.user?.parametros?.exibirRegistroPedidos === true) {
                acao()
                return true
            }
            setLoading(true)
            const novasConfiguracoes = await buscarParametros()
            if (novasConfiguracoes.user.parametros.exibirRegistroPedidos === true) {
                localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
                history.push("/servicos/modulo/registrador-de-pedidos")
                if (!window.getCookie("infoRegistrador")) {
                    enqueueSnackbar("Aqui você registra pedidos para seus clientes.")
                    window.setCookie("infoRegistrador", "true", 9999)
                }
            } else {
                enqueueSnackbar("Esse usuário não possui registrador de pedidos contratado.", {
                    variant: "warning",
                })
            }
        } catch (error) {
            RegistrarLogErro(error, "handleRegistradorDePedidos")
        }
        setLoading(false)
    }

    const handleSistemaAdm = async () => {
        setLoading(true)
        try {
            if (configuracoes.user.perfil === "ADMINISTRADOR") {
                window.open(
                    RepresentanteSelector.getRepresentante().getUrlSistemaAdministrativo(),
                    "_blank",
                    "noopener"
                )
            } else {
                enqueueSnackbar(
                    "Infelizmente seu perfil não tem acesso ao sistema administrativo.",
                    { variant: "warning" }
                )
            }
        } catch (error) {
            enqueueSnackbar("Houve um erro ao direcionar para o sistema administrativo.", {
                variant: "error",
            })
        }
        setLoading(false)
    }

    const izzas = [
        { key: "clientefiel.app", value: "https://izza.online/plugin.html" },
        {
            key: "pinn.delivery",
            value: "https://chrome.google.com/webstore/detail/pinn-delivery/clmomhndbepilodkepckcfmppakgelgi?hl=pt-BR",
        },
    ]

    const getUrlBotWhatsApp = () => {
        let host = window.location.hostname
        let hostReplaced = host.replace("painel.", "")

        let objIzza = izzas.find(item => item.key == hostReplaced)

        if (objIzza) {
            return objIzza.value
        }

        return "https://izza.online/plugin.html"
    }

    const handleIzzaBot = async () => {
        try {
            setLoading(true)
            const configuracoes = await buscarParametros()
            if (configuracoes.user.parametros.modoIzza > 0) {
                localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
                window.open(getUrlBotWhatsApp(), "_blank")
            } else {
                enqueueSnackbar("Esse usuário não possui atentende virtual contratado.", {
                    variant: "warning",
                })
            }
        } catch (error) {
            enqueueSnackbar("Houve um erro ao direcionar para izza bot.", { variant: "error" })
            RegistrarLogErro(error, "handleIzzaBot")
        }
        setLoading(false)
    }

    const handleCaixa = async () => {
        const configuracoes = await buscarParametros()

        localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
        history.push("servicos/modulo/caixa")

        if (!window.getCookie("infoCaixa")) {
            enqueueSnackbar("Aqui você controla seu fluxo de caixa.")
            window.setCookie("infoCaixa", "true", 9999)
        }
    }

    const handleCardapio = () => {
        localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
        history.push("servicos/ferramenta/edicao-cardapio/produtos")

        if (!window.getCookie("infoCardapio")) {
            enqueueSnackbar("Aqui você pode editar seu cardápio.")
            window.setCookie("infoCardapio", "true", 9999)
        }
    }

    const handleAreaDeEntrega = () => {
        localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
        history.push("/servicos/ferramenta/definir-area-de-entrega")

        if (!window.getCookie("infoAreaDeEntrega")) {
            enqueueSnackbar("Aqui você pode editar sua área de entrega.")
            window.setCookie("infoAreaDeEntrega", "true", 9999)
        }
    }

    const handleNotaFiscal = async () => {
        try {
            setLoading(true)
            const configuracoes = await buscarParametros()
            if (configuracoes.user.parametros.ativarPlugNotas === true) {
                localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
                history.push("servicos/modulo/nota-fiscal")
                if (!window.getCookie("infoNotasFiscais")) {
                    enqueueSnackbar("Aqui você pode emitir notas fiscais dos pedidos.")
                    window.setCookie("infoNotasFiscais", "true", 9999)
                }
            } else {
                enqueueSnackbar("Esse usuário não ativou o plug notas.", {
                    variant: "warning",
                })
            }
        } catch (error) {
            enqueueSnackbar("Houve um erro ao direcionar o modulo de nota fiscal.", {
                variant: "error",
            })
            RegistrarLogErro(error, "handleNotaFiscal")
        }
        setLoading(false)
    }

    const handleGeradorQrCode = () => {
        localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
        history.push("/servicos/modulo/gerador-de-qrcode")

        if (!window.getCookie("infoGeradorQrCode")) {
            enqueueSnackbar("Aqui você pode gerar um QRcode a partir de qualquer link.")
            window.setCookie("infoGeradorQrCode", "true", 9999)
        }
    }

    const handleHome = () => {
        localStorage.setItem("dataUltimoServico", JSON.stringify({ data: new Date() }))
        history.push("/servicos")
    }

    return (
        <>
            <ServicosContext.Provider
                value={{
                    autorizacao,
                    handleAceitadorDePedidos,
                    handleCaixa,
                    handleIzzaBot,
                    handleSistemaAdm,
                    handleNotaFiscal,
                    handleRegistradorDePedidos,
                    handleCardapio,
                    handlePedidoDeMesa,
                    configuracoes,
                    buscarParametros,
                    atualizarConfiguracoes,
                    acessarImagensTematicas,
                    handleGeradorQrCode,
                    handleHome,
                    handleAreaDeEntrega,
                }}
            >
                <Routes />
            </ServicosContext.Provider>
        </>
    )
}
