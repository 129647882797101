import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import LoadingContext from "../../../../contexts/loadingContext"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ReaisMask from "../../../../masks/reais"
import TelefoneMask from "../../../../masks/telefone"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Slide from "@material-ui/core/Slide"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AlertFixo from "@material-ui/lab/Alert"
import clsx from "clsx"
import { Col } from "react-bootstrap"

import { EstabelecimentoAberto, RegistrarLogErro } from "../../../../services/clientefiel"

import { InserirItemPedidoMesa, inserirPedidoMesa } from "../../../../services/integracao"

import { calcularValorTotal, carrinhoNovo } from "../../../../pages/RegistradorDePedidos/functions"

import { Select } from "@material-ui/core"
import useParametros from "../../../../contexts/ParametrosContext"
import api from "../../../../services/api"
import { AtualizarStatusPedido, CadastrarPedido } from "../../../../services/cliente"
import SelecaoFormaPagamento from "../../../components/SelecaoFormaPagamento"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    finalizaPedidoModal: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        "& > *, & > * > div, & > * > div > div": {
            display: "flex",
            flex: "1 0 25%",
            gap: "1rem",
            margin: 0,
        },
        "& > div": {
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
        },
        "& #formaPagamento": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        "& #forma-pagamento-imagem": {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            "& > *, & > * > div, & > * > div > div": {
                flex: "none",
            },
        },
    },
    alert: {
        "& > div": {
            flex: 0,
        },
        "& > div:nth-child(2)": {
            flex: 1,
            gap: "0.25rem !important",
        },
        "& button": {
            color: props => (props.ativoMinimoEntregaGratis ? "#dc3545" : "#28a745"),
            fontSize: "1rem",
            borderRadius: "100%",
            width: "2.5rem",
            minWidth: "2.5rem",
            aspectRatio: "1 / 1",
        },
    },
}))

const ErroRequisicao = (erro, aplicativoDados = {}, chamada = "", data = "") => {
    try {
        console.warn("ErroRequisicao> ", erro.request, erro.message)
        RegistrarLogErro(
            erro.message,
            `ErroRequisicao1${chamada !== "" ? " - " + chamada : ""}`,
            data
        ) //, traceId)
        if (erro.message === "Network Error") {
            return "Verifique sua conexão!"
        } else if (erro.message) {
            return "Erro: " + erro.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, `ErroRequisicao1${chamada !== "" ? " - " + chamada : ""}`, data)
    }
}

function tratamentoResponse(response, chamada = "", funcSuccess = () => {}) {
    if (response.data.codErro > 0) {
        RegistrarLogErro(
            response.data.mensagem,
            chamada ?? "tratamentoResponse",
            response.config?.data
        )
        return {
            retornoErro: response.data.codErro,
            mensagem: response.data.mensagem,
        }
    } else if (typeof response.data === "string" && response.data !== "") {
        RegistrarLogErro(response.data, chamada ?? "tratamentoResponse", response.config?.data)
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    } else {
        funcSuccess()
        return response.data
    }
}

export const ObterPedido = async (data, aplicativoDados) => {
    try {
        const response = await api
            .get("cliente/ObterPedidoOtimizado/" + data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "cliente/ObterPedido")
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterPedido => ", response)

        return tratamentoResponse(response, "ObterPedido")
    } catch (error) {
        RegistrarLogErro(error, "ObterPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export default function Index() {
    const {
        setOpenFinalizacaoPedido,
        openFinalizacaoPedido,
        isPedidoMesa,
        numeroMesas,
        isRetiradaNoLocal,
        isDelivery,
        setCarrinho,
        cardapio,
        carrinho,
        setCardapio,
        valorDescontoManual,
        setValorDescontoManual,
        taxaServico,
        setTaxaServico,
        isFreteGratisMinimoEntrega,
        isFreteGratisDesconto,
        setOpenFormasDePagamento,
        descontoAtivoPagamento,
        setDescontoAtivoPagamento,
        ativarDesativarDescontoPagamento,
        ativarDesativarDesconto,
        ativoMinimoEntregaGratis,
        ativarMinimoEntregaGratis,
        descontoAtivo,
        setDescontoAtivo,
        setOpenCardapio,
        enderecoRetiradaNoLocal,
        fecharRegistradorModal,
    } = useContext(RegistradorContext)

    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles({ ativoMinimoEntregaGratis })
    const theme = useTheme()
    const history = useHistory()

    const { setLoading, loading } = useContext(LoadingContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const mesaURL = new URLSearchParams(window.location.search).get("mesa")
    const [mesa, setMesa] = useState(Number(mesaURL) ? mesaURL : null)
    const idPedidoURL = new URLSearchParams(window.location.search).get("idPedido")
    const [idPedido] = useState(idPedidoURL ? Number(idPedidoURL) : null)

    const [cpfNaNota, setCpfNaNota] = useState("")

    const handleChangeCpfNaNota = e => {
        const textoLimpo = e.target.value.replace(/\D/g, "")

        let textoFormatado = textoLimpo

        if (textoLimpo.length === 11) {
            textoFormatado = textoLimpo.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4")
        } else if (textoLimpo.length === 14) {
            textoFormatado = textoLimpo.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                "$1.$2.$3/$4-$5"
            )
        }

        setCpfNaNota(textoFormatado)
    }

    const handleSelecaoFormaDePagamento = e => {
        const formaDePagamento = e

        const cart = Object.assign({}, carrinho)
        cart.formaPagamento = formaDePagamento

        setCarrinho(
            calcularValorTotal({
                carrinho: cart,
                cardapio,
                descontoAtivo,
                isDelivery,
                ativoMinimoEntregaGratis,
                valorDescontoManual,
                descontoAtivoPagamento,
                taxaServico,
            })
        )
    }

    function alterarDescontoManual(e) {
        try {
            setValorDescontoManual(e.floatValue)
            const cart = Object.assign({}, carrinho)
            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento,
                    carrinho: cart,
                    cardapio,
                    descontoAtivo,
                    isDelivery,
                    ativoMinimoEntregaGratis,
                    taxaServico,
                    valorDescontoManual: e.floatValue,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "alterarDescontoManual", e)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const adicionarPedidoAMesa = async pedido => {
        try {
            const response = await inserirPedidoMesa(autorizacao, pedido)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
        } catch (error) {
            RegistrarLogErro(error, "inserirPedidoMesa", pedido)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function alterarTroco(e) {
        try {
            const cart = Object.assign({}, carrinho)
            cart.troco = e.floatValue
            setCarrinho(cart)
        } catch (error) {
            RegistrarLogErro(error, "alterarTroco", e)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function alterarTaxaEntrega(e) {
        try {
            const cart = Object.assign({}, carrinho)
            cart.end.taxaEntrega = e.floatValue
            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento,
                    carrinho: cart,
                    cardapio,
                    descontoAtivo,
                    isDelivery,
                    ativoMinimoEntregaGratis,
                    valorDescontoManual,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "alterarTaxaEntrega", e)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const validarCPF = () => {
        if (cpfNaNota) {
            function calcularDigitoCpf(numeros) {
                let soma = 0
                numeros.forEach((digito, i) => {
                    soma += digito * (i + 2)
                })
                const resto = soma % 11
                return resto < 2 ? 0 : 11 - resto
            }

            function calcularDigitoCnpj(numeros) {
                let i = 2
                let soma = 0

                numeros.forEach(digito => {
                    soma += digito * i
                    if (i === 9) {
                        i = 1
                    }
                    i++
                })
                const resto = soma % 11
                return resto < 2 ? 0 : 11 - resto
            }

            const numerosDocumento = cpfNaNota.replace(/\D/g, "")
            let numeros, digito1, digito2, length

            if (numerosDocumento.length === 11) {
                numeros = numerosDocumento.slice(0, 9).split("").reverse().map(Number)
                length = 11
            } else if (numerosDocumento.length === 14) {
                numeros = numerosDocumento.slice(0, 12).split("").reverse().map(Number)
                length = 14
            } else {
                return alertStart("O CPF ou CNPJ não foi digitado corretamente", "error")
            }

            if (length === 11) {
                digito1 = calcularDigitoCpf(numeros, length)
                numeros.unshift(digito1)
                digito2 = calcularDigitoCpf(numeros)
            } else if (length === 14) {
                digito1 = calcularDigitoCnpj(numeros, length)
                numeros.unshift(digito1)
                digito2 = calcularDigitoCnpj(numeros)
            }

            if (
                digito1 != Number(numerosDocumento[length - 2]) ||
                digito2 != Number(numerosDocumento[length - 1])
            ) {
                return alertStart(
                    `O ${length === 11 ? "CPF" : "CNPJ"} digitado não existe`,
                    "error"
                )
            }
        }
        return true
    }

    const inserirItensRecursivamente = async (idPedido, itens, autorizacao, index = 0) => {
        if (index >= itens.length) {
            alertStart("Pedido Enviado!", "success")
            setOpenCardapio(false)
            setOpenFinalizacaoPedido(false)
            setCarrinho(carrinhoNovo())
            setCardapio({})
            setLoading(false)
            return
        }

        const data = itens[index]

        try {
            const response = await InserirItemPedidoMesa(idPedido, data, autorizacao)
            if (!response.codErro) {
                history.push("/servicos/modulo/gerenciador-de-mesas")
            }

            inserirItensRecursivamente(idPedido, itens, autorizacao, index + 1)
        } catch (error) {
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
            setLoading(false)
        }
    }

    const enviarPedido = async () => {
        const isCPFValido = validarCPF()
        if (!isCPFValido) return

        setLoading(true)

        try {
            localStorage.setItem("taxaServico", carrinho.taxaServico)

            const cart = Object.assign({}, carrinho)
            cart.plataforma = `Central de Pedidos`
            cart.observacao = document.getElementById("observacaoPedido").value

            if (cart?.formaPagamento?.id !== 1) {
                cart.troco = null
            }

            cart.appNome = configuracoes.user?.parametros?.appNome
            cart.idCartelaCompleta = cardapio?.idCartelaCompleta
            cart.token = `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`

            if (isDelivery) {
                if (configuracoes.user.parametros.tipoEntregaBairro == 1) {
                    cart.end.bairroEspecifico = 1
                } else {
                    cart.end.bairroEspecifico = 0
                }
            } else if (isRetiradaNoLocal || isPedidoMesa) {
                cart.end.taxaEntrega = 0.0
            }

            if (cart.valorTotal.toString() === "NaN") {
                setLoading(false)
                alertStart(
                    "O valor total está com algum erro. Verifique os dados digitados ou entre em contato com os desenvolvedores",
                    "error"
                )
                return false
            }

            if (isPedidoMesa) {
                if (!mesa) {
                    setLoading(false)
                    alertStart("Você precisa selecionar uma mesa.", "error")
                    return false
                } else {
                    cart.mesa = mesa
                    cart.taxaAdicional = cart.valorTotal - cart.valorTotal / (1 + taxaServico / 100)
                }
            }

            const telefone = document.getElementById("telefonePedido")?.value?.replace(/\D/gim, "")

            cart.nomePedido = document.getElementById("nomePedido").value
            cart.telefoneCliente = telefone

            if (telefone && telefone.length > 0) {
                if (!(telefone.length === 11 || 10 === telefone.length)) {
                    alertStart("Digite o telefone do cliente", "error")
                    setLoading(false)
                    return false
                }
            }

            //DE ONDE FOI O PEDIDO

            if (isDelivery && (isFreteGratisMinimoEntrega || isFreteGratisDesconto)) {
                cart.valorEntrega = 0
                cart.taxaEntrega = 0
                cart.end.taxaEntrega = 0
            } else if (isDelivery) {
                cart.valorEntrega = cart.end.taxaEntrega
                cart.taxaEntrega = cart.end.taxaEntrega
            }

            if (isDelivery && document.getElementById("mudarParaRetiradaNoLocal").checked) {
                cart.tipoPedido = 1
                cart.valorEntrega = 0
                cart.taxaEntrega = 0
                cart.end = enderecoRetiradaNoLocal()
            }

            if (valorDescontoManual) {
                if (!cart.valorDesconto) {
                    cart.valorDesconto = 0.0
                }
                cart.valorDesconto += valorDescontoManual
            }

            if (cpfNaNota) {
                if (cpfNaNota.length === 14 || cpfNaNota.length === 18) {
                    cart.cpfTitular = cpfNaNota
                } else {
                    alertStart("CPF ou CNPJ incompleto", "warning")
                    setLoading(false)
                    return
                }
            }

            //VERIFICA SE TA ABERTO A LOJA
            const retornoAberto = await EstabelecimentoAberto(
                cart.estabelecimento.id,
                aplicativoDados
            )
            if (retornoAberto.retornoErro) {
                //algum erro EstabelecimentoAberto
                alertStart(retornoAberto.mensagem, "error")
            } else {
                //tudo certoEstabelecimentoAberto

                delete cart.backUpValorDesconto

                cart.estabelecimento.online = retornoAberto.online

                if (idPedido || (isPedidoMesa && idPedido)) {
                    inserirItensRecursivamente(idPedido, cart.itens, autorizacao)
                    return
                }

                if (retornoAberto.online == false) {
                    alertStart("Esse estabelecimento está fechado no momento", "warning")
                } else if (retornoAberto.online === true) {
                    const retornoPedido = await CadastrarPedido(cart, aplicativoDados)
                    if (retornoPedido.retornoErro) {
                        //algum erro CadastrarPedido
                        alertStart(retornoPedido.mensagem, "error")
                    } else {
                        //confirmar pedido
                        const data = {
                            id: retornoPedido.id,
                            token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                            status: 0,
                        }
                        const retornoPedidoCorreto = await AtualizarStatusPedido(
                            data,
                            aplicativoDados
                        )
                        if (retornoPedidoCorreto.retornoErro) {
                            alertStart(retornoPedidoCorreto.mensagem, "error")
                        } else {
                            if (isPedidoMesa) await adicionarPedidoAMesa(retornoPedidoCorreto)

                            alertStart("Pedido Enviado!", "success")
                            setOpenCardapio(false)
                            setOpenFinalizacaoPedido(false)
                            setCarrinho(carrinhoNovo())
                            setCardapio({})
                            isPedidoMesa && history.push("/servicos/modulo/gerenciador-de-mesas")
                        }
                    }
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "enviarPedido", carrinho)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    const parametros = useParametros()

    const largura = window?.innerWidth

    const [nome, setNome] = useState(null)
    const [telefone, setTelefone] = useState(null)
    const [observacao, setObservacao] = useState(null)
    const [pagamentoEditar, setPagamentoEditar] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            if (idPedido) {
                const data = await ObterPedido(idPedido)
                setTelefone(data?.telefoneCliente || null)
                setNome(data?.nomePedido || null)
                setMesa(data?.numeroMesa || null)
                setCpfNaNota(data?.cpfTitular || null)
                setObservacao(data?.observacao || null)
                setPagamentoEditar(data?.formaPagamento || null)
            }
        }
        fetchData()
    }, [idPedido])

    return carrinho.itens.length && cardapio?.id ? (
        <Dialog
            open={openFinalizacaoPedido}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={mobile}
            onClose={() => setOpenFinalizacaoPedido(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="responsive-dialog-informacoes-nome-pedido">
                <IconButton
                    className="setaVoltarModal"
                    onClick={() => {
                        setOpenFinalizacaoPedido(false)
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                {isDelivery
                    ? "PEDIDO PARA DELIVERY"
                    : isRetiradaNoLocal
                    ? "PEDIDO PARA RETIRADA"
                    : "PEDIDO PARA MESA"}
            </DialogTitle>
            <DialogContent>
                {isDelivery ? (
                    <Col xs={12} md={12} lg={12} id={"enderecoDeEntregaFinalizar"}>
                        <Typography>
                            <b>Entregar</b> na{" "}
                            {carrinho.end.logradouro + " - n°: " + carrinho.end.numero} - Bairro{" "}
                            {carrinho.end.bairro} - {carrinho.end.cidade + " - " + carrinho.end.uf}
                            <b> Complemento:</b> {carrinho.end.complemento} e <b>Referência: </b>
                            {carrinho.end.referencia}
                            <br />
                        </Typography>
                    </Col>
                ) : null}
                <div className={classes.finalizaPedidoModal}>
                    {cardapio.minimoEntregaGratis && cardapio.minimoEntregaGratis < 999 ? (
                        <AlertFixo
                            severity={ativoMinimoEntregaGratis ? "success" : "warning"}
                            className={classes.alert}
                            action={
                                <Button onClick={ativarMinimoEntregaGratis}>
                                    {ativoMinimoEntregaGratis ? "-" : "+"}
                                </Button>
                            }
                        >
                            Frete Grátis acima de
                            <b>
                                {Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(cardapio.minimoEntregaGratis)}
                            </b>
                        </AlertFixo>
                    ) : null}
                    {carrinho.valorDesconto || carrinho.backUpValorDesconto ? (
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "1em" }}>
                            <AlertFixo
                                severity={descontoAtivo ? "success" : "warning"}
                                style={{ width: "100%" }}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={ativarDesativarDesconto}
                                    >
                                        {descontoAtivo ? (
                                            <Button style={{ color: "#dc3545" }}>-</Button>
                                        ) : (
                                            <Button style={{ color: "#28a745" }}>+</Button>
                                        )}
                                    </IconButton>
                                }
                            >
                                {cardapio.cupomDesconto?.codigoCupom
                                    ? `Desconto (Cupom: ${cardapio.cupomDesconto?.codigoCupom})`
                                    : cardapio.informacao
                                    ? `Desconto (Info: ${cardapio.informacao}`
                                    : `Desconto`}
                            </AlertFixo>
                        </Col>
                    ) : null}
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            defaultValue={nome ? nome : carrinho?.cliente?.nome}
                            disabled={idPedido ? true : false}
                            fullWidth
                            id="nomePedido"
                            label="Nome"
                            name="nomePedido"
                            type="text"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            defaultValue={telefone ? telefone : carrinho?.cliente?.telefone}
                            disabled={idPedido ? true : false}
                            fullWidth
                            id="telefonePedido"
                            label="Telefone"
                            name="telefonePedido"
                            type="text"
                            InputProps={{
                                inputComponent: TelefoneMask,
                            }}
                        />
                    </div>
                    <div>
                        {isPedidoMesa ? (
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Mesa</InputLabel>
                                <Select
                                    labelId="numeroDaMesaLabel"
                                    id="numeroDaMesa"
                                    value={mesa}
                                    label="Mesa"
                                    fullWidth
                                    variant="outlined"
                                    disabled={idPedido ? true : false}
                                >
                                    {parametros.PUBLIC_NUMERO_MESAS
                                        ? numeroMesas
                                              .slice(0, parametros.PUBLIC_NUMERO_MESAS)
                                              .map(num => (
                                                  <MenuItem
                                                      key={num}
                                                      value={num}
                                                      onClick={() => setMesa(num)}
                                                  >
                                                      {"Mesa " + num}
                                                  </MenuItem>
                                              ))
                                        : numeroMesas.map(num => (
                                              <MenuItem
                                                  key={num}
                                                  value={num}
                                                  onClick={() => setMesa(num)}
                                              >
                                                  {"Mesa " + num}
                                              </MenuItem>
                                          ))}
                                </Select>
                            </FormControl>
                        ) : null}
                        {!isPedidoMesa ||
                        configuracoes.user.parametros.permiteFormaPagamentoMesa ||
                        largura > 500 ? (
                            <SelecaoFormaPagamento
                                handleSelecaoFormaDePagamento={handleSelecaoFormaDePagamento}
                                carrinho={carrinho}
                                formasPagamento={cardapio.formasPagamento}
                                pagamentoEditar={pagamentoEditar}
                            />
                        ) : null}
                    </div>
                    <div>
                        {carrinho.descontoFormaPagamento ? (
                            <FormControl
                                className={clsx(classes.margin, classes.textField)}
                                fullWidth
                                variant="outlined"
                            >
                                {/* <InputLabel htmlFor="valorDesconto">{ (cardapio.cupomDesconto?.codigoCupom) ? `Desconto (Cupom: ${cardapio.cupomDesconto?.codigoCupom})` : ( (cardapio.informacao) ? `Desconto (Info: ${cardapio.informacao}` : `Desconto`)}</InputLabel> */}
                                <InputLabel htmlFor="valorDesconto">
                                    Desconto Forma de Pagamento
                                </InputLabel>
                                <OutlinedInput
                                    id="valorDescontoPagamento"
                                    // type={carrinho.valorDesconto ? 'text' : 'password'}
                                    type={"text"}
                                    value={Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(carrinho.descontoFormaPagamento)}
                                    disabled
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="valorDescontoPagamento"
                                                onClick={ativarDesativarDescontoPagamento}
                                                edge="end"
                                                style={{ width: "2rem", height: "2rem" }}
                                            >
                                                {descontoAtivoPagamento ? (
                                                    <span style={{ color: "#dc3545" }}>-</span>
                                                ) : (
                                                    <span style={{ color: "#28a745" }}>+</span>
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Desconto Forma de Pagamento"
                                />
                            </FormControl>
                        ) : null}
                        {carrinho.valorDesconto || carrinho.backUpValorDesconto ? (
                            <FormControl
                                className={clsx(classes.margin, classes.textField)}
                                fullWidth
                                variant="outlined"
                                disabled={idPedido ? true : false}
                            >
                                <InputLabel htmlFor="valorDesconto">Desconto Automático</InputLabel>
                                <OutlinedInput
                                    id="valorDesconto"
                                    type={"text"}
                                    value={Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(
                                        carrinho.valorDesconto
                                            ? carrinho.valorDesconto
                                            : carrinho.backUpValorDesconto
                                    )}
                                    disabled
                                    onChange={() => {}}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="valorDesconto"
                                                onClick={ativarDesativarDesconto}
                                                edge="end"
                                            >
                                                {descontoAtivo ? (
                                                    <Button style={{ color: "#dc3545" }}>-</Button>
                                                ) : (
                                                    <Button style={{ color: "#28a745" }}>+</Button>
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Desconto Automático"
                                />
                            </FormControl>
                        ) : null}
                        {carrinho?.formaPagamento?.id === 1 ? (
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">R$</InputAdornment>
                                    ),
                                    inputComponent: ReaisMask,
                                }}
                                inputProps={{
                                    style: { textAlign: "right" },
                                }}
                                onChange={alterarTroco}
                                defaultValue="0"
                                id="valorTroco"
                                label="Troco do Dinheiro"
                                name="valorTroco"
                                disabled={idPedido ? true : false}
                            />
                        ) : null}
                        {isDelivery ? (
                            isFreteGratisMinimoEntrega || isFreteGratisDesconto ? null : (
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    defaultValue={carrinho?.end?.taxaEntrega}
                                    fullWidth
                                    id="taxaEntrega"
                                    label="Taxa de Entrega"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">R$</InputAdornment>
                                        ),
                                        inputComponent: ReaisMask,
                                    }}
                                    inputProps={{
                                        style: { textAlign: "right" },
                                    }}
                                    onChange={alterarTaxaEntrega}
                                    name="taxaEntrega"
                                    type="text"
                                    disabled={idPedido ? true : false}
                                />
                            )
                        ) : null}
                        {descontoAtivo || descontoAtivoPagamento ? null : (
                            <TextField
                                variant="outlined"
                                margin="normal"
                                defaultValue={valorDescontoManual}
                                fullWidth
                                id="valorDescontoManual"
                                label="Desconto Manual"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">R$</InputAdornment>
                                    ),
                                    inputComponent: ReaisMask,
                                }}
                                inputProps={{
                                    style: { textAlign: "right" },
                                }}
                                onChange={alterarDescontoManual}
                                name="valorDescontoManual"
                                type="text"
                                disabled={idPedido ? true : false}
                            />
                        )}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="CPF/CNPJ na Nota"
                            value={cpfNaNota}
                            onChange={handleChangeCpfNaNota}
                            disabled={idPedido ? true : false}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="observacaoPedido"
                            label="Observação"
                            name="observacaoPedido"
                            type="text"
                            defaultValue={observacao ? observacao : null}
                            disabled={idPedido ? true : false}
                        />
                    </div>
                    {isDelivery ? (
                        <Col
                            xs={12}
                            md={12}
                            lg={12}
                            style={{ marginTop: "1em", display: "flex", alignItems: "center" }}
                        >
                            <input
                                type="checkbox"
                                color="primary"
                                value={"mudarParaRetiradaNoLocal"}
                                name={"mudarParaRetiradaNoLocal"}
                                onClick={e => {
                                    if (e.target.checked) {
                                        document.getElementById(
                                            "enderecoDeEntregaCarrinho"
                                        ).style.display = "none"
                                    } else {
                                        document.getElementById(
                                            "enderecoDeEntregaCarrinho"
                                        ).style.display = ""
                                    }
                                }}
                                id={"mudarParaRetiradaNoLocal"}
                                style={{ width: "23px", height: "23px", cursor: "pointer" }}
                            />
                            <FormLabel
                                style={{ cursor: "pointer", margin: "0 0 0 1em" }}
                                htmlFor={"mudarParaRetiradaNoLocal"}
                                control={"mudarParaRetiradaNoLocal"}
                            >
                                {"Retirar No Local"}
                            </FormLabel>
                        </Col>
                    ) : null}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpenFinalizacaoPedido(false)
                    }}
                    style={{ color: "" }}
                >
                    Adicionar
                </Button>
                <Button
                    variant="contained"
                    disabled={loading}
                    color="primary"
                    onClick={enviarPedido}
                >
                    {`Confirmar ${Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(carrinho.valorTotal)}`}
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
