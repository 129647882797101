import { Container } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useState } from "react"
import LoadingContext from "../../contexts/loadingContext"
import { RegistrarLogErro } from "../../services/clientefiel"
import {
    cadastrarAreasDeEntregas,
    deletarRaio,
    excluirAreasDeEntregas,
    obterAreasDeEntregas,
    obterLocalizacaoUltimosPedidos,
    obterRaios,
    salvarRaios,
} from "../../services/enderecos"
import { buscarestabelecimentos } from "../../services/integracao"
import MostrarEstabelecimentos from "../Componentes/MostrarEstabelecimentos"
import TelaAreaDeEntrega from "./TelaAreaDeEntregas"

const AreaDeEntregas = () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const lat = params.get("lat")
    const lng = params.get("lng")
    const idEstabelecimentoParam = params.get("idEstabelecimento")

    const autorizacao = localStorage.getItem("chaveSistema")
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [estabelecimento, setEstabelecimento] = useState()
    const [salvar, setSalvar] = useState({ raio: false, area: false })
    const [areas, setAreas] = useState([])
    const [raios, setRaios] = useState([])

    const handleEstabelecimento = estabelecimento => {
        setEstabelecimento(estabelecimento)
    }

    const handleBuscarDadosEstabelecimentos = async () => {
        try {
            setLoading(true)
            const response = await buscarestabelecimentos(autorizacao)

            if (response.retornoErro) {
                console.error(response.mensagem, "error")
            } else {
                setEstabelecimentos(response)
            }
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleBuscarDadosEstabelecimentos()
    }, [])

    useEffect(() => {
        if (idEstabelecimentoParam) {
            const estabelecimentoURL = estabelecimentos.find(
                estabelecimento => estabelecimento.id == idEstabelecimentoParam
            )
            setEstabelecimento(estabelecimentoURL)
        }
    }, [idEstabelecimentoParam, estabelecimentos])

    const [ultimosPedidos, setUltimosPedidos] = useState(null)

    const handleObterLocalizacaoUltimosPedidos = async idEstabelecimento => {
        try {
            setLoading(true)
            const response = await obterLocalizacaoUltimosPedidos(idEstabelecimento)

            if (response?.retornoErro) {
                console.error("Erro", response?.retornoErro)
            } else {
                setUltimosPedidos(response)
            }
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleObterLocalizacaoUltimosPedidos")
        } finally {
            setLoading(false)
        }
    }

    const handleObterAreasDeEntregas = async idEstabelecimento => {
        try {
            setLoading(true)
            const response = await obterAreasDeEntregas(idEstabelecimento, autorizacao)

            if (response.retornoErro) {
                alertStart("Erro ao buscar áreas de entrega", "error")
                throw new Error(response.mensagem)
            }
            return response
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleCadastrarAreasDeEntregas")
            throw error
        } finally {
            setLoading(false)
        }
    }

    const handleObterRaios = async idEstabelecimento => {
        try {
            setLoading(true)
            const response = await obterRaios(idEstabelecimento, autorizacao)

            if (response.retornoErro) {
                alertStart("Erro ao buscar raios", "error")
                throw new Error(response.mensagem)
            }
            return response
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleObterRaios")
            throw error
        } finally {
            setLoading(false)
        }
    }

    const handleDeletarRaio = async (idEstabelecimento, idRaio) => {
        const data = [{ id: idRaio }]
        try {
            setLoading(true)
            const response = await deletarRaio(idEstabelecimento, data, autorizacao)

            if (response.retornoErro) {
                alertStart("Erro ao deletar raio", "error")
                throw new Error(response.mensagem)
            }
            alertStart("Raio deletado com sucesso", "success")
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handeDeletarRaio")
            throw error
        } finally {
            setLoading(false)
        }
    }

    const handleSalvarRaio = async (idEstabelecimento, data) => {
        data.forEach(raio => {
            if (raio.id < 0) {
                raio.id = 0
            }
        })
        try {
            setLoading(true)
            const response = await salvarRaios(idEstabelecimento, data)

            if (response.retornoErro) {
                alertStart("Erro ao salvar raio", "error")
                throw new Error(response.mensagem)
            }
            setRaios(response)
            alertStart("Raio salvo com sucesso", "success")
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleSalvarRaio")
            throw error
        } finally {
            setLoading(false)
        }
    }

    const handleCadastrarAreasDeEntregas = async (idEstabelecimento, data) => {
        data.map(dataElemento => {
            if (String(dataElemento.id).length >= 12) {
                dataElemento.id = null
                dataElemento.polygon.setMap(null)
                delete dataElemento.polygon
            }
        })

        try {
            setLoading(true)
            const response = await cadastrarAreasDeEntregas(idEstabelecimento, data, autorizacao)

            if (response.retornoErro) {
                console.error(response.mensagem)
                alertStart("Erro ao cadastrar área de entrega", "error")
            } else {
                setAreas(response)
                alertStart("Áreas cadsatradas com sucesso", "success")
            }
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleCadastrarAreasDeEntregas")
        } finally {
            setLoading(false)
        }
    }

    const handleExcluirAreaDeEntregas = async (idAreaEntrega, index) => {
        //id diferente do Date.now()
        if (String(idAreaEntrega).length < 12) {
            try {
                setLoading(true)
                const response = await excluirAreasDeEntregas(idAreaEntrega, autorizacao)

                if (response.retornoErro) {
                    console.error(response.mensagem, "error")
                    alertStart("Erro ao excluir área de entrega", "error")
                } else {
                    setAreas(prev => {
                        const areasAtualizadas = [...prev]
                        return areasAtualizadas.filter(prevArea => prevArea.id != idAreaEntrega)
                    })
                }
            } catch (error) {
                console.error(error)
                RegistrarLogErro(error, "handleExcluirAreaDeEntregas")
            } finally {
                setLoading(false)
            }
        } else {
            setAreas(prev => {
                const areasAtualizadas = [...prev]
                areasAtualizadas.splice(index, 1)
                return areasAtualizadas
            })
        }
    }

    return (
        <div>
            <Container style={{ textAlign: "center", paddingTop: "3em" }}>
                {!estabelecimento?.id && estabelecimentos?.length ? (
                    <MostrarEstabelecimentos
                        estabelecimentos={estabelecimentos}
                        handle={handleEstabelecimento}
                    />
                ) : null}
            </Container>
            {estabelecimento?.id && (
                <TelaAreaDeEntrega
                    estabelecimento={estabelecimento}
                    handleObterLocalizacaoUltimosPedidos={handleObterLocalizacaoUltimosPedidos}
                    handleObterAreasDeEntregas={handleObterAreasDeEntregas}
                    handleObterRaios={handleObterRaios}
                    ultimosPedidos={ultimosPedidos}
                    handleDeletarRaio={handleDeletarRaio}
                    handleSalvarRaio={handleSalvarRaio}
                    setSalvar={setSalvar}
                    setAreas={setAreas}
                    areas={areas}
                    handleCadastrarAreasDeEntregas={handleCadastrarAreasDeEntregas}
                    handleExcluirAreaDeEntregas={handleExcluirAreaDeEntregas}
                    salvar={salvar}
                    raios={raios}
                    setRaios={setRaios}
                    lat={lat}
                    lng={lng}
                />
            )}
        </div>
    )
}

export default AreaDeEntregas
