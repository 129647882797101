import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

const autorizacao = localStorage.getItem("chaveSistema")

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

export const obterAreasDeEntregas = async (idEstabelecimento, autorizacao) => {
    const url = "endereco/areaentrega/" + idEstabelecimento
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.get(url, { headers })
        console.warn("obterAreasDeEntregas =>", response)
        return tratamentoResponse(response, "obterAreasDeEntregas")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "obterAreasDeEntregas", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}

export const obterLocalizacaoUltimosPedidos = async idEstabelecimento => {
    const dataAtual = new Date()

    const dataInicio = new Date(dataAtual)
    dataInicio.setDate(dataInicio.getDate() - 30)

    const dataInicioFormatada = dataInicio
        .toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1")

    const dataFimFormatada = dataAtual
        .toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1")

    const url =
        "/pedido/mapacalor/" +
        idEstabelecimento +
        "?dataInicio=" +
        dataInicioFormatada +
        "&dataFim=" +
        dataFimFormatada
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.get(url, { headers })
        console.warn("obterLocalizacaoUltimosPedidos =>", response)
        return tratamentoResponse(response, "obterLocalizacaoUltimosPedidos")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "obterLocalizacaoUltimosPedidos", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}

export const cadastrarAreasDeEntregas = async (idEstabelecimento, data) => {
    const url = "endereco/cadastrar/areaentrega/" + idEstabelecimento
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.post(url, data, { headers })
        console.warn("cadastrarAreasDeEntregas =>", response)
        return tratamentoResponse(response, "cadastrarAreasDeEntregas")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "cadastrarAreasDeEntregas", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}

export const excluirAreasDeEntregas = async idAreaEntrega => {
    const url = "endereco/excluir/areaentrega/" + idAreaEntrega
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.delete(url, { headers })

        console.warn("excluirAreasDeEntregas =>", response)
        return tratamentoResponse(response, "excluirAreasDeEntregas")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "excluirAreasDeEntregas", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}

export const obterRaios = async (idEstabelecimento, autorizacao) => {
    const url = "/endereco/distancias/" + idEstabelecimento
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.get(url, { headers })

        console.warn("obterRaios =>", response)
        return tratamentoResponse(response, "obterRaios")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "obterRaios", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}

export const salvarRaios = async (idEstabelecimento, data) => {
    const url = "/endereco/distancias/" + idEstabelecimento
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.post(url, data, { headers })

        console.warn("salvarRaios =>", response)
        return tratamentoResponse(response, "salvarRaios")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "salvarRaios", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}

export const deletarRaio = async (idEstabelecimento, data) => {
    const url = "/endereco/distancias/" + idEstabelecimento
    const headers = {
        "Content-Type": "application/json",
        Authorization: autorizacao,
    }

    try {
        const response = await api.delete(url, {
            headers,
            data,
        })

        console.warn("deletarRaio =>", response)
        return tratamentoResponse(response, "deletarRaio")
    } catch (error) {
        const mensagemErro = ErroRequisicao(error)
        RegistrarLogErro(error, "deletarRaio", {})
        return { retornoErro: 1, mensagem: mensagemErro || error.message }
    }
}
