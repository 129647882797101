import React, { useContext, useState } from "react"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import AppBar from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Dialog from "@material-ui/core/Dialog"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Slide from "@material-ui/core/Slide"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import CloseIcon from "@material-ui/icons/Close"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import MotorcycleIcon from "@material-ui/icons/Motorcycle"
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation"
import Skeleton from "@material-ui/lab/Skeleton"
import Product from "./components/Produto"
// TODO: Remover clsx
import clsx from "clsx"
import PropTypes from "prop-types"
import { Card, Col, Row } from "react-bootstrap"

import Comida from "../../../../assets/comida.svg"
import Megafone from "../../../../assets/megafone.svg"

import { RegistrarLogErro } from "../../../../services/clientefiel"

import useParametros from "../../../../contexts/ParametrosContext"
import {
    calcularValorTotal,
    procurarCategoria,
    procurarProduto,
    produtoProntoParaCarrinho,
} from "../../../../pages/RegistradorDePedidos/functions"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function TabPanel(props) {
    const { children, ...other } = props

    return (
        <div role="tabpanel" {...other}>
            <Box p={3}>
                <div>{children}</div>
            </Box>
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
}

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

const TaxaAdicional = () => {
    const parametros = useParametros()

    if (!parametros.PUBLIC_TAXA_ADICIONAL) return null

    return (
        <ListItem button divider>
            <ListItemAvatar>
                <Avatar>
                    <AddIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    "Taxa adicional: " +
                    Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(parametros.PUBLIC_TAXA_ADICIONAL)
                }
            />
        </ListItem>
    )
}

export default function Index() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )

    const {
        listaRepeticao,
        setProdutoSelecionado,
        loadingCardapio,
        setLoadingCardapio,
        setOpenFinalizacaoPedido,
        isRetiradaNoLocal,
        isDelivery,
        setCarrinho,
        cardapio,
        openCardapio,
        produtoSelecionado,
        carrinho,
        setCardapio,
        valorDescontoManual,
        ativarDesativarDesconto,
        ativoMinimoEntregaGratis,
        descontoAtivo,
        setOpenCardapio,
        isFreteGratisDesconto,
        isFreteGratisMinimoEntrega,
        descontoAtivoPagamento,
    } = useContext(RegistradorContext)

    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(0)

    const [skipped, setSkipped] = React.useState(new Set())
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const [idCategoriaSelecionada, setIdCategoriaSelecionada] = React.useState()

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const handleCloseCardapio = () => {
        try {
            document.getElementById("procurar-cliente").value = ""
            setOpenCardapio(false)
            setCardapio({})
        } catch (error) {
            RegistrarLogErro(error, "handleCloseCardapio")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const toggleDrawer = (anchor, open) => event => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return
        }
        setState({ ...state, [anchor]: open })
    }

    const toggleDrawerProduto = (anchor, open, produto, idCategoria) => event => {
        try {
            if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
                return
            }
            setState({ ...state, [anchor]: open })
            setActiveStep(0)
            produto.quantidade = 1
            produto.opcoes.forEach(grupo => {
                grupo.quantidade = 0
                grupo.opcoes.forEach(opcao => {
                    opcao.quantidade = 0
                })
            })
            setProdutoSelecionado(produto)
            setIdCategoriaSelecionada(idCategoria)
        } catch (error) {}
    }

    const removerProdutoCarrinho = produto => {
        try {
            console.log("||> removerProdutoCarrinho")
            //funcao para remover quantidade ou produto na tela de cardapio
            var temNoCarrinho = false
            var novoCardapio = cardapio
            //verificar se exite o produto no carrinho
            //altera a quantidade ou remove do carrinho
            let cart = Object.assign({}, carrinho)

            cart.itens.forEach((item, index) => {
                if (temNoCarrinho == false && item.produto.id == produto.id) {
                    const valorProduto = item.valorProdutoHistorico / item.quantidade

                    const qtdRemover = item.produto.qntMultiplicativaProdutos
                        ? item.produto.qntMultiplicativaProdutos
                        : 1
                    item.quantidade -= qtdRemover
                    if (item.quantidade == 0 || item.quantidade < item.produto.qntMinimaProdutos) {
                        item.quantidade = 0 // para item.quantidade < item.qntMinimaProdutos
                        const removido = cart.itens.splice(index, 1) //remvoer do carrinho
                        console.log("removido", removido)
                    } else {
                        item.valorProdutoHistorico -= valorProduto * qtdRemover
                        temNoCarrinho = true
                    }
                }
            })

            cart = calcularValorTotal({
                descontoAtivoPagamento,
                carrinho: cart,
                cardapio,
                descontoAtivo,
                isDelivery,
                ativoMinimoEntregaGratis,
                valorDescontoManual,
                taxaServico: carrinho.taxaServico,
            })
            setCarrinho(cart)
        } catch (error) {
            RegistrarLogErro(error, "removerProdutoCarrinho", { produto, carrinho })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const list = anchor => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            style={{ width: "100%" }}
            role="presentation"
        >
            {
                <List
                    component="div"
                    aria-labelledby="nested-list-subheader"
                    className={classes.rootCarrinho}
                    style={{
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <ListItem button>
                            <ListItemAvatar>
                                <Avatar>
                                    {isDelivery ? (
                                        <MotorcycleIcon />
                                    ) : isRetiradaNoLocal ? (
                                        <TransferWithinAStationIcon />
                                    ) : (
                                        <AirlineSeatReclineNormalIcon />
                                    )}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    isDelivery
                                        ? "Pedido Para Delivery"
                                        : isRetiradaNoLocal
                                        ? "Pedido Para Retirada"
                                        : "Pedido Para Mesa"
                                }
                            />
                            {isDelivery && (
                                <ListItemText
                                    style={{ textAlign: "right" }}
                                    primary={Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(
                                        isFreteGratisDesconto ||
                                            isFreteGratisMinimoEntrega ||
                                            !isDelivery
                                            ? 0.0
                                            : carrinho.end.taxaEntrega
                                    )}
                                />
                            )}
                        </ListItem>

                        {isDelivery ? (
                            <List component="div" disablePadding>
                                <ListItem className={classes.nested} style={{ paddingTop: "0" }}>
                                    {carrinho.end.logradouro + " - n°: " + carrinho.end.numero}
                                </ListItem>
                                <ListItem className={classes.nested} style={{ paddingTop: "0" }}>
                                    {carrinho.end.cidade + " - " + carrinho.end.uf}
                                </ListItem>
                                {carrinho.end.complemento && (
                                    <ListItem
                                        className={classes.nested}
                                        style={{ paddingTop: "0" }}
                                    >
                                        {carrinho.end.complemento}
                                    </ListItem>
                                )}
                                {carrinho.end.referencia && (
                                    <ListItem
                                        className={classes.nested}
                                        style={{ paddingTop: "0" }}
                                    >
                                        {carrinho.end.referencia}
                                    </ListItem>
                                )}
                            </List>
                        ) : null}
                        <Divider />

                        {carrinho.valorDesconto || carrinho.backUpValorDesconto ? (
                            <>
                                <ListItem button>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ConfirmationNumberIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={"Desconto"} />
                                    <ListItemText
                                        style={{ textAlign: "right" }}
                                        primary={Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(
                                            carrinho.valorDesconto
                                                ? carrinho.valorDesconto
                                                : carrinho.backUpValorDesconto
                                        )}
                                        secondary={
                                            <Button
                                                onClick={ativarDesativarDesconto}
                                                style={{
                                                    padding: "0px",
                                                    float: "right",
                                                    fontSize: "80%",
                                                }}
                                                className={
                                                    descontoAtivo
                                                        ? "desconto-ativado"
                                                        : "desconto-desativado"
                                                }
                                            >
                                                {descontoAtivo ? "Ativado" : "Desativado"}
                                            </Button>
                                        }
                                        className={
                                            descontoAtivo
                                                ? "desconto-ativado"
                                                : "desconto-desativado"
                                        }
                                    />
                                </ListItem>
                                <List component="div" disablePadding>
                                    <ListItem
                                        className={classes.nested}
                                        style={{ paddingTop: "0" }}
                                    >
                                        {cardapio?.cupomDesconto?.codigoCupom ? (
                                            <ListItemText
                                                secondary={
                                                    cardapio.cupomDesconto.mensagem
                                                        ? cardapio.cupomDesconto.mensagem
                                                        : `Cupom de desconto ${
                                                              cardapio.cupomDesconto.codigoCupom
                                                          } ${
                                                              cardapio.cupomDesconto.freteGratis
                                                                  ? "com frete grátis"
                                                                  : ""
                                                          }`
                                                }
                                            />
                                        ) : null}
                                        {cardapio?.informacao ? (
                                            <ListItemText
                                                secondary={
                                                    cardapio?.informacao
                                                        ? cardapio?.informacao
                                                        : `Desconto`
                                                }
                                            />
                                        ) : null}
                                    </ListItem>
                                </List>
                                <Divider />
                            </>
                        ) : null}

                        <TaxaAdicional />

                        {carrinho.itens.map((item, index) => (
                            <div key={index} style={{ position: "relative" }}>
                                <ListItem button>
                                    <ListItemAvatar>
                                        {item.produto.urlImagem ? (
                                            <Avatar
                                                style={{
                                                    backgroundImage: `url(${item.produto.urlImagem})`,
                                                    backgroundSize: "cover",
                                                }}
                                            >
                                                <> </>
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                className={"opacity05"}
                                                style={{
                                                    backgroundImage: `url(${
                                                        item.produto.produtoExibicao
                                                            ? Megafone
                                                            : /**Prato */ Comida
                                                    })`,
                                                    backgroundSize: "cover",
                                                    backgroundColor: "white",
                                                }}
                                            >
                                                <> </>
                                            </Avatar>
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.quantidade + "x " + item.produto.nome}
                                    />
                                    <ListItemText
                                        style={{ textAlign: "right" }}
                                        primary={Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(item.valorProdutoHistorico)}
                                        secondary={
                                            <Button
                                                onClick={() => {
                                                    removerProdutoCarrinho(item.produto)
                                                }}
                                                style={{
                                                    padding: "0px",
                                                    float: "right",
                                                    fontSize: "80%",
                                                    color: `#dc3545`,
                                                }}
                                            >
                                                Remover
                                            </Button>
                                        }
                                        className="carrinho-produto-valor"
                                    />
                                </ListItem>

                                {item.produto.promocional &&
                                (cardapio?.valorDesconto || cardapio?.percentualDesconto) ? (
                                    <List key={index} component="div" disablePadding>
                                        <ListItem
                                            className={classes.nested}
                                            style={{ paddingTop: "0" }}
                                        >
                                            <ListItemText
                                                secondary={
                                                    "É promocional, então não recebe desconto."
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                ) : null}
                                {!!item.itensOpcaoProduto.length &&
                                    item.itensOpcaoProduto.map((adicional, index) => (
                                        <List key={index} component="div" disablePadding>
                                            <ListItem
                                                className={classes.nested}
                                                style={{ paddingTop: "0" }}
                                            >
                                                <ListItemText
                                                    secondary={
                                                        adicional.quantidade +
                                                        "x " +
                                                        adicional.opcaoProduto.nome
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    ))}

                                <Divider />
                            </div>
                        ))}
                    </div>
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        className="mt-2"
                        style={{ backgroundColor: "#fff", marginTop: "auto" }}
                    >
                        <Row
                            style={{
                                width: "100%",
                                top: "auto",
                                padding: "0",
                                margin: "0",
                            }}
                        >
                            <b> ({carrinho.itens.length}) Produtos adicionados:</b>
                            <Col xs={12} md={12} lg={12}>
                                <Button onClick={toggleDrawer("right", false)} fullWidth>
                                    Adicionar Mais Produtos
                                </Button>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <Button
                                    variant="contained"
                                    onClick={e => {
                                        toggleDrawer("right", false)(e)

                                        if (carrinho.tipoPedido === 2) {
                                            const formaPagamento = cardapio.formasPagamento.filter(
                                                pg => pg.id === 41
                                            ) // forma pagamento pedido mesa
                                            if (formaPagamento.length) {
                                                carrinho.formaPagamento = formaPagamento[0]
                                            }
                                        }

                                        setOpenFinalizacaoPedido(true)
                                    }}
                                    fullWidth
                                    style={{ background: "#28a745", color: "white" }}
                                >
                                    {`Finalizar ${Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(carrinho.valorTotal)}`}
                                </Button>
                            </Col>
                        </Row>
                    </ListSubheader>
                </List>
            }
        </div>
    )

    const adicionarProduto = (anchor, open, produto) => event => {
        try {
            console.log("||> adicionarProduto")

            if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
                return
            }
            setState({ ...state, [anchor]: open, right: true })

            const grupoExtraIndexes = produto.opcoes.reduce((acc, op, index) => {
                if (op.grupoExtra) acc.push(index)
                return acc
            }, [])

            const produtoSuccess = produtoProntoParaCarrinho(produto)

            for (let i = grupoExtraIndexes.length - 1; i >= 0; i--) {
                produto.opcoes.splice(grupoExtraIndexes[i], 1)
            }

            let cart = Object.assign({}, carrinho)
            cart.itens.push(produtoSuccess)
            cart = calcularValorTotal({
                descontoAtivoPagamento,
                carrinho: cart,
                cardapio,
                descontoAtivo,
                isDelivery,
                ativoMinimoEntregaGratis,
                valorDescontoManual,
                taxaServico: carrinho.taxaServico,
            })
            setCarrinho(cart)
            setActiveStep(0)
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "adicionarProduto", { anchor, open, produto, event })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const [valorPesquisa, setValorPesquisa] = useState()

    return openCardapio && carrinho.estabelecimento ? (
        <Dialog
            fullScreen
            open={openCardapio}
            onClose={handleCloseCardapio}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseCardapio}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>

                    {!mobile ? (
                        <Typography variant="h6" className={classes.title}>
                            {carrinho.estabelecimento.nome}
                            {/* Cardapio Encontrado */}
                        </Typography>
                    ) : null}

                    {/* {
                carrinho.itens.length 
                ? <> */}
                    <Button color="inherit" onClick={toggleDrawer("right", true)}>
                        Carrinho{" "}
                        {Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                            carrinho.valorTotal
                        )}
                    </Button>
                    <Drawer
                        anchor={"right"}
                        open={state["right"]}
                        onClose={toggleDrawer("right", false)}
                    >
                        {list("right")}
                    </Drawer>
                    {/* </>
                : null
              } */}
                </Toolbar>
            </AppBar>

            <Row style={{ margin: "0" }} className={classes.appContainer}>
                {loadingCardapio ? (
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <Skeleton variant="rect" width={"100%"} height={65} />
                            </Col>
                            <Col
                                xs={12}
                                md={3}
                                lg={3}
                                style={{ textAlign: "center", alignSelf: "center" }}
                            >
                                <Skeleton variant="rect" width={"100%"} height={35} />
                            </Col>
                            <Col
                                xs={12}
                                md={3}
                                lg={3}
                                style={{ textAlign: "center", alignSelf: "center" }}
                            >
                                <Skeleton variant="rect" width={"100%"} height={35} />
                            </Col>
                        </Row>

                        {listaRepeticao.map((chave, index) => (
                            <React.Fragment key={index}>
                                <Row style={{ padding: "2em 0" }}>
                                    <Col xs={12} md={5} lg={4}>
                                        <Skeleton
                                            key={chave}
                                            variant="rect"
                                            width={"100%"}
                                            height={35}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} md={4} lg={3}>
                                        <Skeleton
                                            key={chave}
                                            variant="rect"
                                            width={"100%"}
                                            height={258}
                                        />
                                    </Col>
                                    <Col xs={6} md={4} lg={3}>
                                        <Skeleton
                                            key={chave}
                                            variant="rect"
                                            width={"100%"}
                                            height={258}
                                        />
                                    </Col>
                                    <Col xs={6} md={4} lg={3}>
                                        <Skeleton
                                            key={chave}
                                            variant="rect"
                                            width={"100%"}
                                            height={258}
                                        />
                                    </Col>
                                    <Col xs={6} md={4} lg={3}>
                                        <Skeleton
                                            key={chave}
                                            variant="rect"
                                            width={"100%"}
                                            height={258}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ))}
                    </Col>
                ) : (
                    <>
                        <Col xs={12} md={12} lg={12}>
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs={12} md={6} lg={6} className={"mt-3"}>
                                    <TextField
                                        value={valorPesquisa}
                                        id="procurar-produto"
                                        onChange={e => {
                                            const valorDigitado = e.target.value
                                            procurarProduto(
                                                valorDigitado,
                                                cardapio,
                                                configuracoes.user?.parametros
                                                    ?.permitirBuscaPorAdicionais
                                            )
                                            setValorPesquisa(valorDigitado)
                                        }}
                                        fullWidth
                                        label="Procurar..."
                                        variant="outlined"
                                        style={{ background: "#fff" }}
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    md={3}
                                    lg={3}
                                    style={{ alignSelf: "center" }}
                                    className={"mt-3"}
                                >
                                    <FormControl
                                        disabled={false}
                                        variant="outlined"
                                        style={{ width: "100%", background: "#fff" }}
                                    >
                                        <InputLabel id="select-categoria-label">
                                            Categoria
                                        </InputLabel>
                                        <Select
                                            defaultValue={-1}
                                            labelId="select-categoria"
                                            id="select-categoria"
                                            label="Categorias"
                                            onChange={e => {
                                                procurarProduto(
                                                    "",
                                                    cardapio,
                                                    configuracoes.user?.parametros
                                                        ?.permitirBuscaPorAdicionais
                                                )
                                                procurarCategoria(e)
                                                setValorPesquisa("")
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem key={-1} value={-1}>
                                                {"Todas Categorias"}{" "}
                                            </MenuItem>
                                            {cardapio?.categorias.map((categoria, index) =>
                                                categoria.produtos.length ? (
                                                    <MenuItem key={index} value={index}>
                                                        {categoria.nome}
                                                    </MenuItem>
                                                ) : null
                                            )}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} md={12} lg={12} style={{ height: "100%" }}>
                            <TabPanel id="produtosDasCategorias">
                                {cardapio?.id &&
                                    cardapio?.categorias.map((categoria, indexC) =>
                                        categoria.produtos.length ? (
                                            <Row
                                                key={indexC}
                                                id={"primerioC" + indexC}
                                                className={"CardCategoriaCol"}
                                                data-id-categoria={categoria.id}
                                            >
                                                <Col
                                                    xs={12}
                                                    md={12}
                                                    lg={12}
                                                    style={{ marginTop: "1em" }}
                                                >
                                                    <Typography style={{ fontSize: "1.5rem" }}>
                                                        <ArrowRightIcon />{" "}
                                                        {categoria.nome[0].toLocaleUpperCase() +
                                                            categoria.nome
                                                                .substring(1)
                                                                .toLocaleLowerCase()}
                                                    </Typography>
                                                </Col>
                                                {categoria.produtos.map((produto, indexP) =>
                                                    produto?.nome ===
                                                    "Adicionais Associados" ? null : (
                                                        <Col
                                                            xs={6}
                                                            md={4}
                                                            lg={3}
                                                            key={produto.id}
                                                            data-id-produto={produto.id}
                                                            className={`CardProdutoCol`}
                                                        >
                                                            <Card
                                                                className={`${
                                                                    classes.rootProduto
                                                                } ${
                                                                    produto.promocional
                                                                        ? "CardProdutoColPromocional"
                                                                        : null
                                                                }`}
                                                                style={{ height: "100%" }}
                                                                onClick={toggleDrawerProduto(
                                                                    "bottom",
                                                                    true,
                                                                    produto,
                                                                    categoria.id
                                                                )}
                                                            >
                                                                <CardActionArea
                                                                    style={
                                                                        {
                                                                            /*"height": "100%"*/
                                                                        }
                                                                    }
                                                                >
                                                                    <CardMedia
                                                                        className={`${
                                                                            produto.urlImagem
                                                                                ? ""
                                                                                : "opacity05"
                                                                        } produtoDeGrid`}
                                                                        image={
                                                                            produto.urlImagem
                                                                                ? produto.urlImagem
                                                                                : produto.produtoExibicao
                                                                                ? Megafone
                                                                                : /**Prato */ Comida
                                                                        }
                                                                        title={produto.nome}
                                                                    />
                                                                    <CardContent>
                                                                        <Typography
                                                                            gutterBottom
                                                                            style={{
                                                                                fontSize: "1rem",
                                                                            }}
                                                                            className="nomeProduto"
                                                                        >
                                                                            {produto.nome}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="textSecondary"
                                                                            className="descricaoProduto"
                                                                            component="p"
                                                                        >
                                                                            {produto.descricao &&
                                                                            produto.descricao
                                                                                .length > 80
                                                                                ? produto.descricao.substring(
                                                                                      0,
                                                                                      mobile
                                                                                          ? 40
                                                                                          : 80
                                                                                  ) + " [...]"
                                                                                : produto.descricao}
                                                                        </Typography>

                                                                        {produto.produtoExibicao ? (
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="valorProduto"
                                                                                component="p"
                                                                            >
                                                                                Aviso
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="valorProduto"
                                                                                component="p"
                                                                            >
                                                                                {produto.valorDe &&
                                                                                produto.valorDeCalculado >
                                                                                    0 ? (
                                                                                    <span
                                                                                        style={{
                                                                                            textDecorationLine:
                                                                                                "line-through",
                                                                                            fontSize:
                                                                                                "0.8rem",
                                                                                            color: "rgba(220, 53, 99, 0.54)",
                                                                                        }}
                                                                                    >
                                                                                        {Intl.NumberFormat(
                                                                                            "pt-BR",
                                                                                            {
                                                                                                style: "currency",
                                                                                                currency:
                                                                                                    "BRL",
                                                                                            }
                                                                                        ).format(
                                                                                            produto.valorDe
                                                                                        )}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span></span>
                                                                                )}

                                                                                <span className="valorProdutoPromocional">
                                                                                    {" "}
                                                                                    {produto.valorRealCalculado >
                                                                                        0 &&
                                                                                        Intl.NumberFormat(
                                                                                            "pt-BR",
                                                                                            {
                                                                                                style: "currency",
                                                                                                currency:
                                                                                                    "BRL",
                                                                                            }
                                                                                        ).format(
                                                                                            produto.valorRealCalculado
                                                                                        )}
                                                                                </span>
                                                                            </Typography>
                                                                        )}
                                                                    </CardContent>
                                                                </CardActionArea>
                                                            </Card>
                                                        </Col>
                                                    )
                                                )}
                                            </Row>
                                        ) : null
                                    )}
                            </TabPanel>
                        </Col>
                        {cardapio?.id && produtoSelecionado.id ? (
                            <Drawer
                                anchor={"bottom"}
                                open={state["bottom"]}
                                onClose={toggleDrawer("bottom", false)}
                            >
                                <Product
                                    anchor="bottom"
                                    useStyles={useStyles}
                                    produtoSelecionado={produtoSelecionado}
                                    setProdutoSelecionado={setProdutoSelecionado}
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                    alertStart={alertStart}
                                    adicionarProduto={adicionarProduto}
                                    skipped={skipped}
                                    setSkipped={setSkipped}
                                    idCategoriaSelecionada={idCategoriaSelecionada}
                                    clsx={clsx}
                                    state={state}
                                    setState={setState}
                                />
                            </Drawer>
                        ) : null}
                    </>
                )}
            </Row>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
