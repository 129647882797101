import {
    Button,
    Divider,
    IconButton,
    makeStyles,
    Switch,
    TextField,
    Typography,
} from "@material-ui/core"
import { Add, Delete } from "@material-ui/icons"
import {
    Circle,
    DrawingManager,
    GoogleMap,
    HeatmapLayer,
    Marker,
    Polygon,
    useJsApiLoader,
} from "@react-google-maps/api"
import { useContext, useEffect, useState } from "react"
import ServicosContext from "../../contexts/servicosContext"
import Cabecalho from "../Cabecalho"

const useStyles = makeStyles({
    container: {
        margin: "22px 0 0 0",
        width: "100%",
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        "@media (max-width: 1000px)": {
            flexDirection: "column",
            maxHeight: "200vh",
            height: "200vh",
        },
    },
    menuLateral: {
        height: "calc(100vh - 64px)",
        width: "30%",
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "white",
        "@media (max-width: 1000px)": {
            width: "100vw",
        },
    },
    mapaDeCalor: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "0.5em 1em",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
    },
    containerConteudoMenuLateral: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1em",
        flex: 1,
    },
    tituloTela: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    conteudo: {
        width: "100%",
        flex: 1,
    },
    botaoSalvar: {
        display: "flex",
        justifyContent: "end",
        "& > Button": {
            backgroundColor: "#14A44D",
            color: "white",
        },
    },
    containerCards: {
        display: "flex",
        gap: "0.5em",
        flexDirection: "column",
        overflowY: "auto",
        maxHeight: "calc(100vh - 268px)",
        padding: "0.3em",
    },
    tituloRaios: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "1em",
    },
    cardRaio: {
        backgroundColor: "white",
        width: "100%",
        padding: "0.5em 0.5em",
        borderRadius: "10px",
        boxShadow: "0px 0px 3px rgba(0,0,0,0.2)",
        display: "flex",
        alignItems: "center",
        gap: "1em",
        "& >  div": {
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
            width: "100%",
        },
    },
    deletar: {
        backgroundColor: "red",
        borderRadius: "10px",
        color: "white",
    },
    linhaCard: {
        display: "flex",
        gap: "0.5em",
    },
    cardArea: {
        backgroundColor: "white",
        width: "100%",
        padding: "0.5em 0.5em",
        borderRadius: "10px",
        boxShadow: "0px 0px 3px rgba(0,0,0,0.2)",
        display: "flex",
        alignItems: "center",
        gap: "1em",
        justifyContent: "space-around",
    },
    maps: {
        width: "70%",
        height: "100%",
        "@media (max-width: 1000px)": {
            width: "100vw",
        },
    },
})

const TelaAreaDeEntrega = ({
    estabelecimento,
    handleObterLocalizacaoUltimosPedidos,
    handleObterAreasDeEntregas,
    ultimosPedidos,
    handleObterRaios,
    handleDeletarRaio,
    handleSalvarRaio,
    salvar,
    setSalvar,
    setAreas,
    areas,
    handleExcluirAreaDeEntregas,
    handleCadastrarAreasDeEntregas,
    raios,
    setRaios,
    lat,
    lng,
}) => {
    const { configuracoes } = useContext(ServicosContext)

    const classes = useStyles()

    useEffect(() => {
        const fetchData = async () => {
            handleObterLocalizacaoUltimosPedidos(estabelecimento.id)
            const responseAreas = await handleObterAreasDeEntregas(estabelecimento.id)
            setAreas(responseAreas)
            const responseRaios = await handleObterRaios(estabelecimento.id)
            setRaios(responseRaios)
        }
        fetchData()
        if (!configuracoes.user.parametros.tokenGoogleMaps) {
            localStorage.clear()
            window.location.reload()
        }
    }, [])

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: configuracoes.user.parametros.tokenGoogleMaps,
        libraries: ["places", "drawing", "visualization"],
        language: "pt-br",
    })

    const mapCenter =
        estabelecimento.latitude !== 0 && estabelecimento.longitude !== 0
            ? { lat: estabelecimento.latitude, lng: estabelecimento.longitude }
            : { lat: -10.1689, lng: -48.3317 }

    const [mapaDeCalor, setMapaDeCalor] = useState(false)
    const toggleMapaDeCalor = () => {
        setMapaDeCalor(prev => !prev)
    }

    const heatmapData =
        isLoaded && window.google
            ? ultimosPedidos?.map(prev => {
                  return {
                      location: new window.google.maps.LatLng(prev.latitude, prev.longitude),
                      weight: 5,
                  }
              })
            : []

    const markerPedidounico =
        lat && lng
            ? {
                  lat: Number(lat),
                  lng: Number(lng),
              }
            : null

    const handleExcluirPoligono = (i, id) => {
        if (areas) {
            areas[i].polygon.setMap(null)
            setAreas(prev => prev.filter(poligono => poligono.id !== id))
        }
    }

    const handlePolygonComplete = polygon => {
        setSalvar(prev => {
            const objSalvar = { ...prev }
            objSalvar.area = true
            return objSalvar
        })
        const path = polygon.getPath()
        const coordenadas = []

        path.forEach(latLng => {
            coordenadas.push({ lat: latLng.lat(), lng: latLng.lng() })
        })

        setAreas(prev => [
            ...prev,
            {
                id: Date.now(),
                nomeArea: null,
                taxaEntrega: 0,
                minimoEntregaGratis: null,
                pontos: coordenadas,
                idEstabelecimento: estabelecimento.id,
                polygon: polygon,
            },
        ])
    }
    const handleClickDeletarRaio = async (raio, i) => {
        if (raio.id > 0) {
            const response = await handleDeletarRaio(estabelecimento.id, raio.id)
            if (response?.codErro > 0) {
                return
            }
        }
        setRaios(prev => {
            const raiosAtualizados = [...prev]
            raiosAtualizados.splice(i, 1)
            return raiosAtualizados
        })
    }

    const cores = ["red", "orange", "yellow", "green", "blue", "indigo", "violet", "black"]

    return (
        <div>
            <Cabecalho pagina={"Editar área de entrega"} />
            <div className={classes.container}>
                <div className={classes.menuLateral}>
                    <div className={classes.containerConteudoMenuLarteral}>
                        <div className={classes.tituloTela}>
                            <Typography variant="h5">Áreas de Entrega</Typography>
                        </div>

                        <div className={classes.conteudo}>
                            <div className={classes.botaoSalvar}>
                                <Button
                                    onClick={() => {
                                        if (salvar.area) {
                                            handleCadastrarAreasDeEntregas(
                                                estabelecimento.id,
                                                areas
                                            )
                                            setSalvar(prev => {
                                                const objSalvar = { ...prev }
                                                objSalvar.area = false
                                                return objSalvar
                                            })
                                        }
                                        if (salvar.raio) {
                                            handleSalvarRaio(estabelecimento.id, raios)
                                            setSalvar(prev => {
                                                const objSalvar = { ...prev }
                                                objSalvar.raio = false
                                                return objSalvar
                                            })
                                        }
                                    }}
                                >
                                    Salvar
                                </Button>
                            </div>
                            <Divider style={{ padding: "0" }} />
                            <div className={classes.containerCards}>
                                <div className={classes.tituloRaios}>
                                    <Typography variant="h6">Raios</Typography>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setSalvar(prev => {
                                                const objSalvar = { ...prev }
                                                objSalvar.raio = true
                                                return objSalvar
                                            })
                                            setRaios([
                                                ...raios,
                                                {
                                                    id: -Math.random(),
                                                    distanciaMaxima: 0,
                                                    distanciaMaximaValue: 0,
                                                    taxaEntrega: 0,
                                                    taxaEntregaNoturna: null,
                                                    minimoEntregaGratis: null,
                                                    estabelecimento: null,
                                                    versao: 0,
                                                    kmConvert: null,
                                                },
                                            ])
                                        }}
                                    >
                                        <Add size="small" />
                                    </IconButton>
                                </div>
                                <Divider />
                                {raios.length > 0 &&
                                    raios.map((raio, i) => (
                                        <div
                                            className={classes.cardRaio}
                                            key={raio.id}
                                            id={raio.raio}
                                        >
                                            <div>
                                                <div className={classes.linhaCard}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Raio (m)"
                                                        defaultValue={raio.distanciaMaximaValue}
                                                        onChange={e => {
                                                            const valor = e.target.value
                                                            raio.distanciaMaxima = valor
                                                            raio.distanciaMaximaValue =
                                                                Number(valor)
                                                        }}
                                                        onBlur={() => {
                                                            setSalvar(prev => {
                                                                const objSalvar = { ...prev }
                                                                objSalvar.raio = true
                                                                return objSalvar
                                                            })
                                                        }}
                                                        style={{ flex: 1 }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Taxa (R$)"
                                                        defaultValue={raio.taxaEntrega}
                                                        onChange={e => {
                                                            const valor = e.target.value
                                                            raio.taxaEntrega = valor
                                                        }}
                                                        onBlur={() => {
                                                            setSalvar(prev => {
                                                                const objSalvar = { ...prev }
                                                                objSalvar.raio = true
                                                                return objSalvar
                                                            })
                                                        }}
                                                        style={{ flex: 1 }}
                                                    />
                                                </div>
                                                <div className={classes.linhaCard}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Taxa Noturna (R$)"
                                                        defaultValue={raio.taxaEntregaNoturna}
                                                        onChange={e => {
                                                            const valor = e.target.value
                                                            raio.taxaEntregaNoturna = valor
                                                        }}
                                                        onBlur={() => {
                                                            setSalvar(prev => {
                                                                const objSalvar = { ...prev }
                                                                objSalvar.raio = true
                                                                return objSalvar
                                                            })
                                                        }}
                                                        style={{ flex: 1 }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Valor entre. grátis"
                                                        defaultValue={raio.minimoEntregaGratis}
                                                        onChange={e => {
                                                            const valor = e.target.value
                                                            raio.minimoEntregaGratis = valor
                                                        }}
                                                        onBlur={() => {
                                                            setSalvar(prev => {
                                                                const objSalvar = { ...prev }
                                                                objSalvar.raio = true
                                                                return objSalvar
                                                            })
                                                        }}
                                                        style={{ flex: 1 }}
                                                    />
                                                </div>
                                            </div>
                                            <IconButton
                                                className={classes.deletar}
                                                onClick={() => {
                                                    handleClickDeletarRaio(raio, i)
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </div>
                                    ))}
                                <Divider />

                                <Typography variant="h6">Áreas</Typography>
                                <Divider />
                                {areas?.length > 0 &&
                                    areas
                                        ?.sort((a, b) => a.pontos.length - b.pontos.length)
                                        .filter(area => area.id)
                                        .map((area, i) => (
                                            <div
                                                className={classes.cardArea}
                                                key={area.id}
                                                id={area.nome}
                                            >
                                                <Typography style={{ color: cores[i % 7] }}>
                                                    Área {i + 1}
                                                </Typography>
                                                <IconButton
                                                    className={classes.deletar}
                                                    onClick={() => {
                                                        handleExcluirAreaDeEntregas(area.id, i)
                                                        if (String(area.id).length >= 12) {
                                                            handleExcluirPoligono(i, area.id)
                                                        }
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        ))}
                            </div>
                        </div>
                    </div>

                    <div className={classes.mapaDeCalor}>
                        <Typography>Mapa de calor</Typography>
                        <Switch
                            value={mapaDeCalor}
                            onClick={toggleMapaDeCalor}
                            disabled={!ultimosPedidos}
                            color="default"
                        />
                    </div>
                </div>

                {isLoaded && (
                    <div className={classes.maps}>
                        <GoogleMap
                            id="google-map"
                            mapContainerStyle={{
                                width: "100%",
                                height: "100%",
                                borderLeft: "solid 1px rgba(0,0,0,0.3)",
                            }}
                            center={mapCenter}
                            zoom={mapCenter.lat !== -10.1689 ? 15 : 4}
                            options={{
                                streetViewControl: false,
                            }}
                        >
                            <DrawingManager
                                paths={areas}
                                onPolygonComplete={handlePolygonComplete}
                                options={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        position: window.google.maps.ControlPosition.TOP_CENTER,
                                        drawingModes: ["polygon"],
                                    },
                                }}
                            />
                            {mapaDeCalor ? (
                                <HeatmapLayer
                                    data={heatmapData}
                                    options={{ radius: 30, opacity: 0.5, maxIntensity: 100 }}
                                />
                            ) : null}
                            {areas?.length > 0 &&
                                areas
                                    ?.sort((a, b) => a.pontos.length - b.pontos.length)
                                    ?.map((area, i) => (
                                        <Polygon
                                            paths={area.pontos}
                                            options={{
                                                fillColor: "black",
                                                fillOpacity: 0.2,
                                                strokeColor: cores[i % 7],
                                                strokeWeight: 2,
                                                draggable: false,
                                            }}
                                        />
                                    ))}
                            {raios?.length > 0
                                ? raios?.map(raio => (
                                      <Circle
                                          radius={raio.distanciaMaximaValue}
                                          center={mapCenter}
                                          options={{
                                              strokeColor: "black",
                                              strokeOpacity: 1,
                                              strokeWeight: 1,
                                              fillOpacity: 0,
                                          }}
                                      />
                                  ))
                                : null}
                            {markerPedidounico && <Marker position={markerPedidounico} />}
                        </GoogleMap>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TelaAreaDeEntrega
