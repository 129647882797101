import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

export const buscarNotas = async function (data, basicUsuario) {
    try {
        const response = await api.post(`notafiscal/buscarNotas/filtro`, data, {
            headers: { "Content-Type": "application/json", Authorization: basicUsuario },
        })
        console.log("buscarNotas => ", response)
        return {
            response: response.data.response,
            message: response.data.message,
            status: response.status,
            statusText: response.statusText,
        }
    } catch (error) {
        RegistrarLogErro(error, "buscarNotas", data)
        return {
            response: [],
            message: `Buscar notas fisicas não foi possivel, tire uma foto desse erro e avise para os responsáveis. ${error.message}`,
            status: 1000,
            statusText: `error`,
        }
    }
}

export const BaixarNotaFiscalPDF = async function (data, basicUsuario) {
    try {
        const response = await api.get("notafiscal/baixarNotaPDF/" + data, {
            responseType: "blob",
            headers: { "Content-Type": "application/json", Authorization: basicUsuario },
        })

        console.log("BaixarNotaFiscalPDF => ", response)

        // Não posso usar o tratamentoResponse, pois o retorno pode ser um PDF
        return response.data
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "BaixarNotaFiscalPDF", data)
    }
}
