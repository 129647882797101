//INIT
import React, { useContext, useEffect, useState } from "react"

import { useSnackbar } from "notistack"

import LoadingContext from "../../contexts/loadingContext"
import RegistradorContext from "../../contexts/registradorContext"
import ServicosContext from "../../contexts/servicosContext"

import CadastrarCliente from "./components/CadastrarCliente"
import CadastrarEndereco from "./components/CadastrarEndereco"
import Cardapio from "./components/Cardapio"
import ClientesEncontrados from "./components/ClientesEncontrados"
import EditarCliente from "./components/EditarCliente"
import EnderecosEncontrados from "./components/EnderecosEncontrados"
import FinalizacaoPedido from "./components/FinalizacaoPedido"
import FinalizarMesa from "./components/FinalizarMesa"
import FormasDePagamento from "./components/FormasDePagamento"
import MesasAbertas from "./components/MesasAbertas"
import ParteInicial from "./components/ParteInicial"
import VisualizarPedido from "./components/VisualizarPedido"

//FUNCTIONS \ REQUESTS

import { buscarestabelecimentos } from "../../services/integracao"

import {
    calcularValorAPartirDe,
    calcularValorTotal,
    carrinhoNovo,
    enderecoClienteNaLoja,
    enderecoRetiradaNoLocal,
} from "../../pages/RegistradorDePedidos/functions"

import {
    listarClientes,
    ListarEstabelecimentosPorEndereco,
    ObterCardapioCompletoV1,
    ObterCardapioPedidoMesa,
    RegistrarLogErro,
} from "../../services/clientefiel"

import { CadastrarUsuarioBasico, listarEnderecos } from "../../services/cliente"

//FILES
import Cabecalho from "../Cabecalho"
import "./styles.css"

//FRAMEWORK
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import Fab from "@material-ui/core/Fab"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Slide from "@material-ui/core/Slide"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import { default as AlertFixo } from "@material-ui/lab/Alert"
import Skeleton from "@material-ui/lab/Skeleton"
import { Card, Col, Row } from "react-bootstrap"

const qz = require("qz-tray")

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const numeroMesas = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
]
const listaRepeticao = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

export default function RecebedorDePedidos({ iframe, fecharRegistradorModal }) {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )

    const classes = useStyles()

    const { handleAceitadorDePedidos, configuracoes, atualizarConfiguracoes, buscarParametros } =
        useContext(ServicosContext)

    const { enqueueSnackbar } = useSnackbar()

    const [valorDescontoManual, setValorDescontoManual] = React.useState(0.0)
    const [taxaServico, setTaxaServico] = useState(Number(localStorage.getItem("taxaServico")) ?? 0)
    const [descontoAtivo, setDescontoAtivo] = React.useState(false)
    const [descontoAtivoPagamento, setDescontoAtivoPagamento] = React.useState(false)
    const [ativoMinimoEntregaGratis, setAtivoMinimoEntregaGratis] = React.useState(true)
    const [impressorasQZ, setImpressorasQZ] = React.useState([])
    const { setLoading, loading } = useContext(LoadingContext)
    const [openFormasDePagamento, setOpenFormasDePagamento] = React.useState(false)
    const [openFinalizacaoPedido, setOpenFinalizacaoPedido] = React.useState(false)
    const [loadingClientes, setLoadingClientes] = React.useState(false)
    const [openListaClientes, setOpenListaClientes] = React.useState(false)
    const [listaClientes, setListaClientes] = React.useState([])
    const [loadingEnderecos, setLoadingEnderecos] = React.useState(false)
    const [openListaEnderecos, setOpenListaEnderecos] = React.useState(false)
    const [listaEnderecos, setListaEnderecos] = React.useState([])
    const [openGerenciarMesa, setOpenGerenciarMesa] = React.useState(false)
    const [listaMesas, setListaMesas] = React.useState([])
    const [openGerenciarPedidosMesa, setOpenGerenciarPedidosMesa] = React.useState(false)
    const [pedidosDaMesa, setPedidosDaMesa] = React.useState({})
    const [openCadastroCliente, setOpenCadastroCliente] = React.useState(false)
    const [openCadastroEndereco, setOpenCadastroEndereco] = React.useState(false)
    const [loadingEstabelecimentos, setLoadingEstabelecimentos] = React.useState(false)
    const [openEstabelecimentoDisponiveis, setOpenEstabelecimentoDisponiveis] =
        React.useState(false)
    const [listaEstabelecimentosQueDisponiveis, setListaEstabelecimentosDisponiveis] =
        React.useState([])
    const [openCardapio, setOpenCardapio] = React.useState(false)
    console.log("openCardapio", openCardapio)
    const [loadingCardapio, setLoadingCardapio] = React.useState(false)
    const [cardapio, setCardapio] = React.useState({})
    const [cardapioMesaGuardado, setCardapioMesaGuardado] = useState()
    const [informacoesParaBuscarCardapio, setInformacoesParaBuscarCardapio] = React.useState({
        idEndereco: null,
        idCliente: null,
        idEstabelecimento: null,
        token: "a~I?;;+_@!AL^",
    })
    const [produtoSelecionado, setProdutoSelecionado] = React.useState({})
    const [visualizarPedido, setVisualizarPedido] = React.useState({})

    const [editarCliente, setEditarCliente] = React.useState({})
    const [estabelecimentosDisponiveis, setEstabelecimentosDisponiveis] = React.useState([])
    const [carrinho, setCarrinho] = useState(carrinhoNovo())
    const [finalizarMesa, setFinalizarMesa] = useState(false)
    console.log("carrinho", carrinho)

    const isDelivery = carrinho?.tipoPedido !== 1 && carrinho?.tipoPedido !== 2 && carrinho.end
    const isRetiradaNoLocal = carrinho?.tipoPedido === 1 ? true : false
    const isPedidoMesa = carrinho?.tipoPedido === 2 ? true : false
    const isFreteGratisMinimoEntrega =
        carrinho?.itens?.length > 0 &&
        cardapio?.minimoEntregaGratis < 999 &&
        cardapio?.minimoEntregaGratis > 0 &&
        carrinho.valorTotal > cardapio?.minimoEntregaGratis &&
        ativoMinimoEntregaGratis
    const isFreteGratisDesconto =
        descontoAtivo && cardapio.informacao && cardapio.informacao.includes("frete grátis")

    console.log("isPedidoMesa", isPedidoMesa)
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    async function verPedido(pedido, mesa) {
        try {
            setVisualizarPedido({ ...pedido, mesa: mesa })
        } catch (error) {
            RegistrarLogErro(error, "verPedido")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleSelecionarEndereco = async (endereco, index) => {
        try {
            endereco.appNome = configuracoes.user?.parametros?.appNome
            console.log("||> handleSelecionarEndereco", endereco)
            setInformacoesParaBuscarCardapio({
                ...informacoesParaBuscarCardapio,
                idEndereco: endereco.id,
            })
            setOpenEstabelecimentoDisponiveis(true)
            setLoadingEstabelecimentos(true)
            endereco.token = "a~I?;;+_@!AL^"

            if (configuracoes.user.parametros.tipoEntregaBairro == 1) {
                endereco.bairroEspecifico = 1
            } else {
                endereco.bairroEspecifico = 0
            }

            const response = await ListarEstabelecimentosPorEndereco(autorizacao, endereco)
            console.log("response", response)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            const estabelecimentosDoUsuario = response.filter(e => {
                return estabelecimentosDisponiveis.filter(ee => ee?.id == e?.id).length
            })

            const estabelecimentos = estabelecimentosDoUsuario.filter(
                loja => loja.possuiEnderecoAtendido === true
            )

            const cart = Object.assign({}, carrinho)
            cart.end = endereco

            console.log("cart", cart)

            cart.tipoPedido = 0

            listaEnderecos[index] = endereco

            setCarrinho(cart)
            if (estabelecimentos.length === 1) {
                handleSelecionarEstabelecimento(estabelecimentos[0], cart)
            }

            setListaEstabelecimentosDisponiveis(estabelecimentos)
            setLoadingEstabelecimentos(false)
        } catch (error) {
            RegistrarLogErro(error, "selecionouBairroSelect")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleSelecionarEstabelecimento = (estabelecimento, carrinho) => {
        try {
            console.log("||> handleSelecionarEstabelecimento", estabelecimento, carrinho)
            const infos = {
                ...informacoesParaBuscarCardapio,
                idEstabelecimento: estabelecimento.id,
            }
            setInformacoesParaBuscarCardapio(infos)
            const cart = Object.assign({}, carrinho)
            cart.estabelecimento = estabelecimento
            cart.end.taxaEntrega = estabelecimento.taxaEntrega

            const cardapio = handleBuscarCardapio({ infos, cart })

            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento,
                    carrinho: cart,
                    cardapio,
                    descontoAtivo: false,
                    isDelivery,
                    ativoMinimoEntregaGratis,
                    valorDescontoManual,
                    taxaServico: taxaServico,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "handleSelecionarEstabelecimento")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleBuscarDadosEstabelecimentos = async () => {
        try {
            const response = await buscarestabelecimentos(autorizacao)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            setEstabelecimentosDisponiveis(response)
            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
        return {}
    }

    const handleSelecionarCliente = async cliente => {
        try {
            console.log("||> handleSelecionarCliente", cliente)
            setInformacoesParaBuscarCardapio({
                ...informacoesParaBuscarCardapio,
                idCliente: cliente.id,
            })
            setOpenListaEnderecos(true)
            setLoadingEnderecos(true)
            const data = {
                idCliente: cliente.id,
                idEstabelecimento: configuracoes.user.parametros.estabelecimento.id,
                token: "a~I?;;+_@!AL^",
            }
            const response = await listarEnderecos(
                autorizacao,
                data,
                configuracoes.user?.parametros?.appNome
            )

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            const cart = Object.assign({}, carrinho)

            const objClientePedido = {
                id: cliente.id,
                telefone: cliente.telefone,
                nome: cliente.nome,
            }

            cart.cliente = objClientePedido
            setCarrinho(cart)

            setListaEnderecos(response)
            setLoadingEnderecos(false)
        } catch (error) {
            RegistrarLogErro(error, "handleSelecionarCliente")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const verificarSeTemQZ = () => {
        try {
            if (qz.websocket.isActive()) {
                buscarImpressoras()
            } else {
                qz.websocket
                    .connect()
                    .then(() => {
                        qz.printers
                            .details()
                            .then(function (data) {
                                for (var i = 0; i < data.length; i++) {
                                    console.log(
                                        data[i].name +
                                            " " +
                                            data[i].driver +
                                            " " +
                                            data[i].density +
                                            " " +
                                            data[i].trays
                                    )
                                }
                            })
                            .catch(function (e) {
                                console.error(e)
                            })

                        //console.log('Conectado QZ!');
                        buscarImpressoras()
                    })
                    .catch(err => {
                        console.error("Erro QZ", err.message)
                        // alertStart("Impressao automatica não identifica!", "error")
                        if (impressorasQZ.length > 0) {
                            setImpressorasQZ([])
                        }
                    })
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarSeTemQZ")
        }
    }

    useEffect(() => {
        try {
            verificarSeTemQZ()
            handleBuscarDadosEstabelecimentos()
        } catch (error) {
            RegistrarLogErro(error, "useEffect")
        }
    }, [])

    const buscarImpressoras = async () => {
        try {
            // TODO:
            // if (!configuracoes.printer?.impressoras?.length) {
            //     configuracoes.printer.impressoras = [await qz.printers.getDefault()]
            //     atualizarConfiguracoes(configuracoes)
            //     // localStorage.setItem("configuracoes", JSON.stringify(configuracoes))
            // }

            const printers = await qz.printers.find()
            setImpressorasQZ(printers)
        } catch (error) {
            // RegistrarLogErro(error, "buscarImpressoras")
        }
    }

    const realizarPedidoMesa = pedido => {
        try {
            console.log("||> realizarPedidoMesa", pedido)
            const cart = Object.assign({}, carrinho)
            cart.status = 99
            cart.tipoPedido = 2
            cart.end = enderecoClienteNaLoja()
            cart.cliente = pedido.cliente
            cart.estabelecimento = pedido.estabelecimento
            cart.mesa = pedido.mesa

            const infos = {
                ...informacoesParaBuscarCardapio,
                idEstabelecimento: pedido.estabelecimento.id,
                idCliente: pedido.cliente.id,
            }
            setInformacoesParaBuscarCardapio(infos)

            setPedidosDaMesa({})
            setOpenGerenciarPedidosMesa(false)
            setOpenGerenciarMesa(false)

            const cardapio = handleBuscarCardapio({ infos, cart })

            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento,
                    carrinho: cart,
                    cardapio,
                    descontoAtivo: false,
                    isDelivery,
                    ativoMinimoEntregaGratis,
                    valorDescontoManual,
                    taxaServico: taxaServico,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "realizarPedidoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    async function getCardapioMesaAtualizado(infos) {
        try {
            const expirationTime = Date.now() + 300000
            localStorage.setItem("expiration-cardapio-mesa", expirationTime)

            const cardapioMesa = await ObterCardapioPedidoMesa(autorizacao, infos) //cardápio para mesa
            setCardapioMesaGuardado(cardapioMesa)

            return cardapioMesa
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "getCardapioMesaAtualizado")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    async function getCardapioMesaAtualizado(infos) {
        const expirationTime = Date.now() + 300000
        localStorage.setItem("expiration-cardapio-mesa", expirationTime)

        const cardapioMesa = await ObterCardapioPedidoMesa(autorizacao, infos) //cardápio para mesa
        setCardapioMesaGuardado(cardapioMesa)

        return cardapioMesa
    }

    const handleBuscarCardapio = async ({ infos, cart = carrinho }) => {
        try {
            console.log("||> handleBuscarCardapio", infos)
            document.getElementById("procurar-cliente").value = ""
            setLoadingCardapio(true)
            setOpenCardapio(true)
            setOpenEstabelecimentoDisponiveis(false)
            setOpenListaEnderecos(false)
            setOpenListaClientes(false)

            setListaClientes([])
            setListaEnderecos([])
            setListaEstabelecimentosDisponiveis([])

            let response = null

            if (cart.tipoPedido === 2) {
                const isCardapioExpirado =
                    !cardapioMesaGuardado ||
                    !localStorage.getItem("expiration-cardapio-mesa") ||
                    Date.now() > localStorage.getItem("expiration-cardapio-mesa")
                if (isCardapioExpirado) response = await getCardapioMesaAtualizado(infos)
                else response = cardapioMesaGuardado
            } else {
                response = await ObterCardapioCompletoV1(autorizacao, infos)
            }

            console.log("ObterCardapioCompletoV1 response", response)
            if (response.retornoErro) {
                localStorage.removeItem("cardapio-mesa")
                alertStart(response.mensagem, "error")
                return false
            }

            response.categorias.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    produto.valorRealCalculado = calcularValorAPartirDe(produto)
                    produto.valorDeCalculado = calcularValorAPartirDe(produto, true)
                })
            })

            setCardapio(response)
            setLoadingCardapio(false)
            return response
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "handleBuscarCardapio")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        return {}
    }

    const handleCloseEstabelecimentoQueEntregam = () => {
        try {
            document.getElementById("procurar-cliente").value = ""
            setOpenEstabelecimentoDisponiveis(false)
            setListaEstabelecimentosDisponiveis([])
        } catch (error) {
            RegistrarLogErro(error, "handleCloseCardapio")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleCloseGerenciamentoPedidosMesa = () => {
        try {
            document.getElementById("procurar-cliente").value = ""
            setOpenGerenciarPedidosMesa(false)
        } catch (error) {
            RegistrarLogErro(error, "handleCloseGerenciamentoPedidosMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function ativarDesativarDesconto() {
        try {
            const ativo = !descontoAtivo
            setValorDescontoManual(0.0)
            setDescontoAtivo(ativo)
            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento,
                    carrinho,
                    cardapio,
                    descontoAtivo: ativo,
                    isDelivery,
                    ativoMinimoEntregaGratis,
                    valorDescontoManual: 0.0,
                    taxaServico: taxaServico,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "ativarDesativarDesconto")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function ativarDesativarDescontoPagamento() {
        try {
            const ativo = !descontoAtivoPagamento
            setValorDescontoManual(0.0)
            setDescontoAtivoPagamento(ativo)
            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento: ativo,
                    carrinho,
                    cardapio,
                    descontoAtivo,
                    isDelivery,
                    ativoMinimoEntregaGratis,
                    valorDescontoManual: 0.0,
                    taxaServico,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "ativarDesativarDesconto")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    function ativarMinimoEntregaGratis() {
        try {
            const ativo = !ativoMinimoEntregaGratis
            setAtivoMinimoEntregaGratis(ativo)
            setCarrinho(
                calcularValorTotal({
                    descontoAtivoPagamento,
                    carrinho,
                    cardapio,
                    descontoAtivo,
                    isDelivery,
                    ativoMinimoEntregaGratis: ativo,
                    valorDescontoManual,
                    taxaServico: taxaServico,
                })
            )
        } catch (error) {
            RegistrarLogErro(error, "ativarMinimoEntregaGratis")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const verificarExistente = async valor => {
        try {
            const config = configuracoes?.user?.parametros?.estabelecimento?.id
                ? configuracoes
                : await buscarParametros()

            const data = {
                idEstabelecimento: config.user.parametros.estabelecimento.id,
                nomeCliente: valor
                    .replace("(", "")
                    .replace(")", "")
                    .replace("-", "")
                    .replace(" ", ""),
                maxResultado: 100,
                token: "a~I?;;+_@!AL^",
            }

            const response = await listarClientes(autorizacao, data)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return []
            }

            return response
        } catch (error) {
            RegistrarLogErro(error, "verificarExistente", { valor })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const cadastrarUsuario = async e => {
        try {
            e.preventDefault()
            if (document.getElementById("nome-cadastro")?.value === "") {
                alertStart("Nome do cliente é necessário.", "error")
                return false
            } else if (document.getElementById("telefone-cadastro")?.value === "") {
                alertStart("Telefone do cliente é necessário.", "error")
                return false
            }

            const usuariosExistentes = await verificarExistente(
                document.getElementById("telefone-cadastro").value
            )

            console.log("usuariosExistentes", usuariosExistentes)
            if (usuariosExistentes.length) {
                document.getElementById("procurar-cliente").value =
                    document.getElementById("telefone-cadastro")?.value
                setListaClientes(usuariosExistentes)
                setOpenListaClientes(true)
                setOpenCadastroCliente(false)
                alertStart("Já existe usuário com esse telefone", "warning")
                return false
            }

            const data = {
                appNome: configuracoes.user?.parametros?.appNome,
                tipoUsuario: 3, //0-permanente, 1-temporario/semcadastro, 2-temporario/mesa, 3-temporario/registrador
                nome: document.getElementById("nome-cadastro")?.value,
                telefone: document.getElementById("telefone-cadastro")?.value,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }

            setLoading(true)
            setOpenCadastroCliente(false)

            const retornoUsuario = await CadastrarUsuarioBasico(autorizacao, data)
            if (retornoUsuario.retornoErro) {
                //algum erro EstabelecimentoAberto
                alertStart(retornoUsuario.mensagem, "error")
            } else {
                // document.getElementById('nome-cadastro').value = ''
                // document.getElementById('telefone-cadastro').value = ''
                handleSelecionarCliente(retornoUsuario)
                alertStart("Usuário Cadastrado com sucesso.", "success")
            }
        } catch (error) {
            RegistrarLogErro(error, "cadastrarUsuario", {
                appNome: configuracoes.user?.parametros?.appNome,
                nomeCadastro: document.getElementById("nome-cadastro")?.value,
                telefoneCadastro: document.getElementById("telefone-cadastro")?.value,
            })
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    return (
        <RegistradorContext.Provider
            value={{
                fecharRegistradorModal,
                descontoAtivoPagamento,
                setDescontoAtivoPagamento,
                ativarDesativarDescontoPagamento,
                pedidosDaMesa,
                handleBuscarCardapio,
                finalizarMesa,
                handleSelecionarEstabelecimento,
                impressorasQZ,
                visualizarPedido,
                setProdutoSelecionado,
                enderecoRetiradaNoLocal,
                visualizarPedido,
                setVisualizarPedido,
                loadingCardapio,
                setLoadingCardapio,
                openCardapio,
                setOpenCardapio,
                produtoSelecionado,
                setCardapio,
                setOpenFinalizacaoPedido,
                openFinalizacaoPedido,
                isPedidoMesa,
                numeroMesas,
                isRetiradaNoLocal,
                setOpenFormasDePagamento,
                openFormasDePagamento,
                ativarDesativarDesconto,
                ativoMinimoEntregaGratis,
                ativarMinimoEntregaGratis,
                setFinalizarMesa,
                setOpenCadastroEndereco,
                openCadastroEndereco,
                isDelivery,
                carrinho,
                descontoAtivo,
                setDescontoAtivo,
                isFreteGratisMinimoEntrega,
                cardapio,
                setCarrinho,
                setLoadingClientes,
                setOpenListaClientes,
                setListaClientes,
                listaEnderecos,
                openListaEnderecos,
                setOpenListaEnderecos,
                setOpenGerenciarMesa,
                setListaMesas,
                listaMesas,
                valorDescontoManual,
                setValorDescontoManual,
                taxaServico,
                setTaxaServico,
                openListaClientes,
                loadingClientes,
                listaClientes,
                listaRepeticao,
                setInformacoesParaBuscarCardapio,
                informacoesParaBuscarCardapio,
                setLoadingEnderecos,
                loadingEnderecos,
                handleSelecionarCliente,
                setEditarCliente,
                isFreteGratisDesconto,
                setOpenCadastroCliente,
                cadastrarUsuario,
                openCadastroCliente,
                setPedidosDaMesa,
                setOpenGerenciarPedidosMesa,
                openGerenciarMesa,

                editarCliente,
                setEditarCliente,
                setListaEnderecos,
                isDelivery,

                setLoadingEstabelecimentos,
                estabelecimentosDisponiveis,
                setListaEstabelecimentosDisponiveis,
                setOpenEstabelecimentoDisponiveis,

                handleSelecionarEndereco,
            }}
        >
            <Container component="main" style={{ marginTop: "6em" }} className="container-buscando">
                {!window.frameElement && !iframe ? (
                    <>
                        <Cabecalho pagina={"Registrador de Pedidos"} />
                        <Fab
                            variant="extended"
                            color="primary"
                            aria-label="add"
                            className={classes.fixed}
                            onClick={handleAceitadorDePedidos}
                        >
                            <CheckIcon style={{ marginRight: "5px" }} />
                            Recebedor de Pedidos
                        </Fab>
                    </>
                ) : null}

                <ParteInicial />

                <MesasAbertas />

                <VisualizarPedido />

                <FinalizarMesa />

                {openGerenciarPedidosMesa && (
                    <Dialog
                        fullScreen
                        open={openGerenciarPedidosMesa}
                        onClose={handleCloseGerenciamentoPedidosMesa}
                        TransitionComponent={Transition}
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseGerenciamentoPedidosMesa}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    ({pedidosDaMesa?.pedidosMesa?.length}) Pedidos Encontrados
                                </Typography>

                                {!mobile ? (
                                    <Row>
                                        <Col xs={12} md={12} lg={12} style={{ margin: "0.5em 0" }}>
                                            {impressorasQZ.length > 0 ? (
                                                <React.Fragment>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <InputLabel
                                                            id="impressoras"
                                                            style={{
                                                                background: "white",
                                                                "border-radius": "10px",
                                                                padding: "2px 10px",
                                                            }}
                                                        >
                                                            Imprimir 1º Opção
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            labelId="Impressora-P-Imprimir"
                                                            id="Impressora-P-Imprimir"
                                                            defaultValue={
                                                                configuracoes.printer.impressora
                                                                    ? configuracoes.printer
                                                                          .impressora
                                                                    : "vazio"
                                                            }
                                                            style={{
                                                                width: "200px",
                                                                background: "white",
                                                            }}
                                                            onChange={e => {
                                                                configuracoes.printer.impressora =
                                                                    e.target.value
                                                                localStorage.setItem(
                                                                    "configuracoes",
                                                                    JSON.stringify(configuracoes)
                                                                )
                                                                atualizarConfiguracoes(
                                                                    configuracoes
                                                                )
                                                            }}
                                                            label="Impressora P/ Imprimir"
                                                        >
                                                            <MenuItem value="vazio">
                                                                <em>Nenhuma</em>
                                                            </MenuItem>

                                                            {impressorasQZ.map((printer, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={printer}
                                                                >
                                                                    {printer}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </React.Fragment>
                                            ) : (
                                                <>
                                                    <Button
                                                        onClick={e => {
                                                            verificarSeTemQZ()
                                                        }}
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor: "#6c757d",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Identificar Printer
                                                    </Button>

                                                    <Button
                                                        onClick={e => {
                                                            window.location.href =
                                                                "https://clientefielsp.s3-sa-east-1.amazonaws.com/Cliente+Fiel+Printer.zip"
                                                        }}
                                                        variant="contained"
                                                        style={{
                                                            marginLeft: "10px",
                                                            backgroundColor: "#6c757d",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Download Printer
                                                    </Button>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                            </Toolbar>
                        </AppBar>
                        <Container className={classes.appContainer}>
                            <Row>
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ marginBottom: "0.5em", textAlign: "center" }}
                                >
                                    <Fab
                                        variant="extended"
                                        aria-label="add"
                                        style={{ background: "#dc3545", color: "white" }}
                                        className={classes.fixedMesaFechar}
                                        onClick={() => {
                                            setFinalizarMesa(true)
                                        }}
                                    >
                                        <CloseIcon style={{ marginRight: "5px" }} />
                                        Fechar Mesa
                                    </Fab>

                                    <Fab
                                        variant="extended"
                                        aria-label="add"
                                        style={{ background: "#28a745", color: "white" }}
                                        className={classes.fixedMesa}
                                        onClick={() => {
                                            realizarPedidoMesa(
                                                pedidosDaMesa?.pedidosMesa[
                                                    pedidosDaMesa.pedidosMesa.length - 1
                                                ]
                                            )
                                        }}
                                    >
                                        <AddIcon style={{ marginRight: "5px" }} />
                                        Adicionar Pedido
                                    </Fab>

                                    {/* <Button size="small" onClick={() => {realizarPedidoMesa(pedidosDaMesa?.pedidosMesa[pedidosDaMesa.pedidosMesa.length-1])}} style={{"color":"#28a745"}}>
                        REALIZAR PEDIDO
                    </Button> */}
                                </Col>

                                {pedidosDaMesa?.pedidosMesa?.map((pedido, index) => (
                                    <Col
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        key={index}
                                        style={{ marginBottom: "0.5em" }}
                                    >
                                        <Card className={classes.root} style={{ height: "100%" }}>
                                            <CardActionArea
                                                onClick={() => {
                                                    verPedido(pedido, pedidosDaMesa.numeroMesa)
                                                }}
                                                style={{
                                                    height: "calc(100% - 30px)",
                                                    flexFlow: "column-reverse",
                                                    alignItems: "normal",
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        margin: 0,
                                                        textAlign: "",
                                                        width: "100%",
                                                        padding: "1em",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col
                                                        xs={3}
                                                        md={3}
                                                        lg={3}
                                                        style={{
                                                            padding: 0,
                                                            textAlign: "center",
                                                            fontSize: "5em",
                                                        }}
                                                    >
                                                        {index + 1}º
                                                    </Col>
                                                    <Col xs={9} md={9} lg={9}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            component="p"
                                                        >
                                                            {pedido.strDataRealizacao}
                                                        </Typography>

                                                        <Typography gutterBottom component="h2">
                                                            {pedido.cliente?.nome}
                                                        </Typography>

                                                        <Typography
                                                            className={"StatusEstabelecimento"}
                                                            variant="body2"
                                                            component="p"
                                                        >
                                                            {Intl.NumberFormat("pt-BR", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }).format(pedido.valorTotal)}
                                                        </Typography>
                                                    </Col>
                                                </Row>
                                            </CardActionArea>

                                            <CardActions
                                                style={{ cursor: "pointer", padding: "0" }}
                                            >
                                                <Typography
                                                    variant={"button"}
                                                    style={{ textAlign: "center", padding: "3px" }}
                                                    className={
                                                        pedido.valorDesconto
                                                            ? "botaoVerde naoArredondado"
                                                            : "botaoCinza naoArredondado"
                                                    }
                                                >
                                                    {pedido.valorDesconto
                                                        ? `DESCONTO DE ${Intl.NumberFormat(
                                                              "pt-BR",
                                                              { style: "currency", currency: "BRL" }
                                                          ).format(pedido.valorDesconto)}`
                                                        : `SEM DESCONTO`}
                                                </Typography>
                                            </CardActions>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Dialog>
                )}

                <ClientesEncontrados />

                <EnderecosEncontrados />

                {openEstabelecimentoDisponiveis && (
                    <Dialog
                        fullScreen
                        open={openEstabelecimentoDisponiveis}
                        onClose={handleCloseEstabelecimentoQueEntregam}
                        TransitionComponent={Transition}
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseEstabelecimentoQueEntregam}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {!loadingEstabelecimentos
                                        ? "(" + listaEstabelecimentosQueDisponiveis.length + ")"
                                        : null}{" "}
                                    Estabelecimentos Encontrados
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Container className={classes.appContainer}>
                            <Row>
                                {loadingEstabelecimentos ? (
                                    <>
                                        <Col
                                            xs={12}
                                            md={12}
                                            lg={12}
                                            style={{ marginBottom: "0.5em" }}
                                        >
                                            <Skeleton
                                                variant="rect"
                                                width={"100%"}
                                                height={48}
                                                style={{ marginBottom: "1em" }}
                                            />
                                        </Col>
                                        {listaRepeticao.map(chave => (
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                key={chave}
                                                style={{ marginBottom: "0.5em" }}
                                            >
                                                <Skeleton
                                                    variant="rect"
                                                    width={"100%"}
                                                    height={145}
                                                />
                                            </Col>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <Col
                                            xs={12}
                                            md={12}
                                            lg={12}
                                            style={{ marginBottom: "0.5em" }}
                                        >
                                            <AlertFixo
                                                severity="warning"
                                                style={{ width: "100%", marginBottom: "1em" }}
                                            >
                                                {isDelivery ? (
                                                    <>
                                                        Você buscou estabelecimentos que entregam no
                                                        endereço <b>{carrinho?.end?.logradouro}</b>{" "}
                                                        de <b>{carrinho?.cliente?.nome}</b>.
                                                    </>
                                                ) : isRetiradaNoLocal ? (
                                                    `Você buscou estabelecimentos que aceitam retirada no local.`
                                                ) : (
                                                    `Você buscou estabelecimentos que aceitam pedidos na mesa.`
                                                )}
                                            </AlertFixo>
                                        </Col>
                                        {listaEstabelecimentosQueDisponiveis.length ? (
                                            listaEstabelecimentosQueDisponiveis.map(
                                                (estabelecimento, index) => (
                                                    <Col
                                                        xs={12}
                                                        md={6}
                                                        lg={4}
                                                        key={index}
                                                        style={{ marginBottom: "0.5em" }}
                                                    >
                                                        <Card
                                                            className={classes.root}
                                                            style={{ height: "100%" }}
                                                        >
                                                            <CardActionArea
                                                                onClick={() =>
                                                                    handleSelecionarEstabelecimento(
                                                                        estabelecimento,
                                                                        carrinho
                                                                    )
                                                                }
                                                                style={{
                                                                    flexFlow: "column-reverse",
                                                                    alignItems: "normal",
                                                                }}
                                                            >
                                                                <Row
                                                                    style={{
                                                                        margin: 0,
                                                                        textAlign: "",
                                                                        width: "100%",
                                                                        padding: "1em",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        xs={3}
                                                                        md={3}
                                                                        lg={3}
                                                                        style={{ padding: 0 }}
                                                                    >
                                                                        <CardMedia
                                                                            component="img"
                                                                            className={"logoLojas"}
                                                                            alt={
                                                                                estabelecimento.nome
                                                                            }
                                                                            image={
                                                                                estabelecimento.urlLogo
                                                                            }
                                                                            title={
                                                                                estabelecimento.nome
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col xs={9} md={9} lg={9}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            component="p"
                                                                        >
                                                                            {
                                                                                estabelecimento.endereco
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            gutterBottom
                                                                            component="h2"
                                                                        >
                                                                            {estabelecimento.nome}
                                                                        </Typography>
                                                                    </Col>
                                                                </Row>
                                                            </CardActionArea>

                                                            <CardActions
                                                                style={{
                                                                    cursor: "pointer",
                                                                    padding: "0",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant={"button"}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "3px",
                                                                    }}
                                                                    className={
                                                                        estabelecimento.online
                                                                            ? "botaoVerde naoArredondado"
                                                                            : "botaoVermelho naoArredondado"
                                                                    }
                                                                    onClick={() =>
                                                                        handleSelecionarEstabelecimento(
                                                                            estabelecimento,
                                                                            carrinho
                                                                        )
                                                                    }
                                                                >
                                                                    {estabelecimento.online
                                                                        ? "Aberto"
                                                                        : estabelecimento.abreAs
                                                                        ? estabelecimento.abreAs
                                                                        : "Fechado"}{" "}
                                                                    -{" "}
                                                                    {isDelivery
                                                                        ? Intl.NumberFormat(
                                                                              "pt-BR",
                                                                              {
                                                                                  style: "currency",
                                                                                  currency: "BRL",
                                                                              }
                                                                          ).format(
                                                                              estabelecimento.taxaEntrega
                                                                          )
                                                                        : isRetiradaNoLocal
                                                                        ? "Aceita Retirada"
                                                                        : "Aceita Cliente Na Mesa"}
                                                                </Typography>
                                                            </CardActions>
                                                        </Card>
                                                    </Col>
                                                )
                                            )
                                        ) : (
                                            <Col
                                                xs={12}
                                                md={12}
                                                lg={12}
                                                style={{
                                                    textAlign: "center",
                                                    marginBottom: "0.5em",
                                                }}
                                            >
                                                <Typography>
                                                    {" "}
                                                    Nenhum estabelecimento foi encontrado.{" "}
                                                </Typography>
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>
                        </Container>

                        {/* <List className={classes.appContainer}>
                {loadingEstabelecimentos
                  ? listaRepeticao.map(chave => (
                    <>
                      <Skeleton key={chave} variant="rect" width={"100%"} height={72} />
                      <Divider />
                    </>
                  ))
                : (listaEstabelecimentosQueDisponiveis.length
                  ? listaEstabelecimentosQueDisponiveis.map(estabelecimento => (
                    <>
                      <ListItem button onClick={() => handleSelecionarEstabelecimento(estabelecimento)} style={{"textAlign": "center"}} className={estabelecimento.online ? "colorSuccess": "colorDanger"}>
                        <ListItemText 
                          primary={isDelivery ? `(${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((estabelecimento.taxaEntrega))}) ${estabelecimento.nome}` : `${estabelecimento.nome}`} 
                          secondary={<>
                                      <Typography>{estabelecimento.online ? "Aberto": (estabelecimento.abreAs ? estabelecimento.abreAs : "Fechado")}</Typography>
                                      <span>{estabelecimento.endereco}</span>
                                    </>} />
                        
                      </ListItem>
                      <Divider />
                    </>))
                    
                  : <>
                      <ListItem button  style={{"textAlign": "center"}} >
                        <ListItemText 
                          primary={"Nenhum estabelecimento entrega nesse endereço"} 
                          secondary={"Tente novamente com outras opções."} />
                        
                      </ListItem>
                    </>
                  )}
              </List> */}
                    </Dialog>
                )}

                <Cardapio />

                <FormasDePagamento />

                <FinalizacaoPedido />

                <EditarCliente />

                <CadastrarCliente />

                <CadastrarEndereco />
            </Container>
        </RegistradorContext.Provider>
    )
}
